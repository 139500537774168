import React, { useState, useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import undoIcon from "../../../../assets/images/undoIcon.svg";
import closeModalIcon from "../../../../assets/images/deleteDocumentIcon.svg";

import "./SignatureBox.scss";

export const SignatureBox = (props) => {
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [canvasWidth, setCanvasWidth] = useState(0);

  let signatureBoxRef = React.createRef(null);
  let signatureControlsRef = React.createRef(null);
  let sigPadRef = React.createRef(null);

  useEffect(() => {
    if (sigPadRef.current && props.value) {
      sigPadRef.current.fromDataURL(props.value, {
        width: canvasWidth,
        height: canvasHeight,
      });
    }
  }, [sigPadRef, canvasWidth, canvasHeight]);

  useEffect(() => {
    const resizeCanvas = () => {
      if (signatureBoxRef?.current && signatureControlsRef?.current) {
        let newWidth = signatureBoxRef.current.clientWidth;
        let newHeight =
          signatureBoxRef.current.clientHeight -
          signatureControlsRef.current.clientHeight;

        setCanvasWidth(newWidth);
        setCanvasHeight(newHeight);
      }
    };
    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();
    return () => window.removeEventListener("resize", resizeCanvas);
  }, [signatureBoxRef, signatureControlsRef]);

  const clear = () => {
    if (sigPadRef?.current) {
      props.onChange("");
      sigPadRef.current.clear();
    }
  };

  const saveAndClose = () => {
    props.onChange(sigPadRef.current.getCanvas().toDataURL("image/png"));
    props.onFinish();
  };

  return (
    <div className={"signatureBox"} ref={signatureBoxRef}>
      <div>
        <button onClick={() => props.onFinish()} className="closeModal">
          <img alt="" src={closeModalIcon} />
        </button>
        {canvasHeight > 0 && canvasWidth > 0 && (
          <SignaturePad
            penColor="black"
            canvasProps={{
              width: `${canvasWidth}px`,
              height: `${canvasHeight}px`,
            }}
            ref={sigPadRef}
          />
        )}
      </div>
      <div className="signautureControls" ref={signatureControlsRef}>
        <div className="separationLine"></div>
        <div className="undocontrols">
          <img alt="" src={undoIcon} />
          <button type="button" className="clearButton" onClick={clear}>
            נקה
          </button>
        </div>
        <button type="button" className="acceptButton" onClick={saveAndClose}>
          אישור
        </button>
      </div>
    </div>
  );
};
export default SignatureBox;
