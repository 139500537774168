import React, { useCallback } from "react";
import TextInput from "../../../FormElements/TextInput/TextInput";
import Button from "@material-ui/core/Button";
import "./VerificationForm.scss";

function VerificationForm(props) {
  const {
    values: { userVerificationCode },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
  } = props;

  const change = useCallback(
    (name, e) => {
      e.persist();
      handleChange(e);
      setFieldTouched(name, true, false);
    },
    [handleChange, setFieldTouched]
  );

  return (
    <form
      className="verificationForm"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextInput
        value={userVerificationCode}
        id={"userVerificationCode"}
        touched={touched}
        errors={errors}
        // onChange={change.bind(null, "userVerificationCode")}
        onChange={(event) => change("userVerificationCode", event)}
        label={"קוד אימות"}
      />

      <Button
        className="submitButton"
        type="submit"
        variant="contained"
        disabled={!isValid}
      >
        לחץ לאימות
      </Button>
    </form>
  );
}

export default VerificationForm;
