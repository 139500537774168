import styled from "styled-components";
import { keyframes } from "styled-components";

export const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
    }
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  z-index: 1000;
  background-color: #fafafa;

  @media (max-width: 600px) {
    background-color: white;
  } ;
`;

export const LoadingIndicator = styled.img`
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-name: ${animation};
`;
