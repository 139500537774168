import * as Yup from "yup";

export const FinishProcessSchema = Yup.object().shape({
  deliveryTime: Yup.string(),
  pharmacistAdvice: Yup.string().required(),
  orderAddons: Yup.string().nullable(),
  isApproveCommercialContent: Yup.boolean(),
  digitalSignature: Yup.string(),
  morningDelivery: Yup.boolean(),
  noonDelivery: Yup.boolean(),
  eveningDelivery: Yup.boolean(),
  isPolicyAndUsageAccepted: Yup.boolean().required("שדה זה חובה למילוי"),
});
