import React, { useState, useEffect, useMemo } from "react";
import FileUploadProgressBar from "./FileUploadProgressBar/FileUploadProgressBar";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadDocumentsRequest,
  deleteDocumentsRequest,
} from "../../../store/slices/userUploadDocumentsSlice";
import FileUploadResult from "./FileUploadResult/FileUploadResult";
import FileUploadInput from "./FileUploadInput/FileUploadInput";

import "./UploadDocument.scss";

function UploadDocument({ id, category, text }) {
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (files.length === 0) {
      return;
    }
    dispatch(uploadDocumentsRequest({ category, files: Array.from(files) }));
  }, [dispatch, files, files.length, category]);

  const globalUploadStatus = useSelector(
    (state) => state.userUploadDocumentsReducer
  );

  const uploadStatus = useMemo(() => {
    const categoryUploadStatus = {};
    for (let key of Object.keys(globalUploadStatus)) {
      if (globalUploadStatus[key].category === category) {
        categoryUploadStatus[key] = globalUploadStatus[key];
      }
    }
    return categoryUploadStatus;
  }, [globalUploadStatus, category]);

  const uploadStatusKeys = useMemo(() => Object.keys(uploadStatus), [
    uploadStatus,
  ]);
  const uploadStatusValues = useMemo(() => Object.values(uploadStatus), [
    uploadStatus,
  ]);
  const totalFiles = uploadStatusValues.length;

  const userHasUploadedFiles = totalFiles > 0;
  const uploadPercentage =
    uploadStatusValues.find((status) => !status.isUploadFinished)?.percentage ||
    0;

  let currentFile = useMemo(() => {
    return uploadStatusValues.findIndex(
      (statusValue) => !statusValue.isUploadFinished
    );
  }, [uploadStatusValues]);

  let isUploadFinished = useMemo(() => currentFile === -1, [currentFile]);

  const resendDocument = (fileName) => {
    for (const file of files) {
      if (file.name === fileName) {
        dispatch(uploadDocumentsRequest(file));
        return;
      }
    }
  };

  const uploadFile = (fileList) => {
    let updatedFiles = [];
    if (fileList && fileList.length > 0) {
      fileList = [...fileList];
      updatedFiles = fileList.filter(
        (file) => !(file.name in Object.keys(globalUploadStatus))
      );

      setFiles(updatedFiles);
    }
  };

  const deleteSelectedDocument = (fileName) => {
    let selectedFileId = null;
    if (fileName in uploadStatus) {
      selectedFileId = uploadStatus[fileName].id;
      dispatch(deleteDocumentsRequest({ id: selectedFileId, fileName }));
    }
  };

  return (
    <div className="uploadDocumentUtills">
      {userHasUploadedFiles && !isUploadFinished && (
        <FileUploadProgressBar
          fileNumber={currentFile + 1}
          totalFiles={totalFiles}
          progress={uploadPercentage}
        />
      )}
      {userHasUploadedFiles &&
        isUploadFinished &&
        uploadStatusKeys.map((fileName) => (
          <FileUploadResult
            fileName={fileName}
            key={fileName}
            deleteSelectedDocument={deleteSelectedDocument}
            fileUploadStatus={uploadStatus[fileName]}
            resendDocument={resendDocument}
          />
        ))}
      {!userHasUploadedFiles && (
        <FileUploadInput
          id={id}
          text={text}
          category={category}
          uploadFile={uploadFile}
          multiple={category === "prescriptions" && true}
        />
      )}
    </div>
  );
}

export default UploadDocument;
