import React from "react";
import { loaderTexts } from "../../texts";
import loaderImg from "../../assets/images/loader.svg";

import { LoaderOverlay, LoadingIndicator } from "./style";

function Loader() {
  return (
    <LoaderOverlay>
      <LoadingIndicator src={loaderImg} />
      {loaderTexts.loaderText}
    </LoaderOverlay>
  );
}

export default Loader;
