import React from "react";
import { connect } from "react-redux";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import HomePage from "../../components/pages/HomePage/HomePage";
import ExistingClubMember from "../../components/pages/ExistingClubMemberPage/ExistingClubMemberPage";
import RegisterPage from "../../components/pages/RegisterPage/RegisterPage";
import VerificationPage from "../../components/pages/VerificationPage/VerificationPage";
import UserDetailsPage from "../../components/pages//UserDetailsPage/UserDetailsPage";
import UploadDocumentsPage from "../../components/pages/UploadDocumentsPage/UploadDocumentsPage";
import AdditionalDetailsPage from "../../components/pages/AdditionalDetailsPage/AdditionalDetailsPage";
import LoginPage from "../../components/pages/LoginPage/LoginPage";
import FinishProcessPage from "../../components/pages/FinishProcessPage/FinishProcessPage";
import ErrorNotificationPage from "../ErrorNotificationPage/ErrorNotificationPage";
import SuccessNotificationPage from "../SuccessNotificationPage/SuccessNotificationPage";
import SignatureBox from "../FormElements/DigitalSignatureInput/SignatureBox/SignatureBox";
import Loader from "../Loader/Loader";
import { routes } from "../../routes";

import "./Main.scss";

function Main({ isLoading }) {
  return (
    <div className="main">
      {isLoading && <Loader />}
      <BrowserRouter>
        <Switch>
          <Route path={routes.homePage} exact component={HomePage} />
          <Route path={routes.loginPage} exact component={LoginPage} />
          <Route path={routes.registerPage} exact component={RegisterPage} />
          <Route
            path={routes.loginVerificationPage}
            exact
            component={VerificationPage}
          />
          <Route
            path={routes.registerVerificationPage}
            exact
            component={VerificationPage}
          />
          <Route
            path={routes.userDetailsPage}
            exact
            component={UserDetailsPage}
          />
          <Route
            path={routes.uploadDocumentPage}
            exact
            component={UploadDocumentsPage}
          />
          <Route
            path={routes.additionalDetailsPage}
            exact
            component={AdditionalDetailsPage}
          />
          <Route
            path={routes.finishProcessPage}
            exact
            component={FinishProcessPage}
          />
          <Route
            path={routes.errorNotification}
            exact
            component={ErrorNotificationPage}
          />
          <Route
            path={routes.successNotification}
            exact
            component={SuccessNotificationPage}
          />
          <Route
            path={routes.existingClubMemberNotification}
            exact
            component={ExistingClubMember}
          />
          <Route path={routes.signatureBox} exact component={SignatureBox} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.loaderReducer.isLoading,
});

export default connect(mapStateToProps, null)(Main);
