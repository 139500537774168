import React, { useCallback } from "react";
import Button from "@material-ui/core/Button";
import TextInput from "../../../FormElements/TextInput/TextInput";
import "./RegisterForm.scss";

export const RegisterForm = (props) => {
  const {
    values: { userEmail, userPhone },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
  } = props;

  const change = useCallback(
    (name, e) => {
      e.persist();
      handleChange(e);
      setFieldTouched(name, true, false);
    },
    [handleChange, setFieldTouched]
  );

  return (
    <form
      className="registerForm"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextInput
        value={userEmail}
        id={"userEmail"}
        touched={touched}
        errors={errors}
        onChange={(event) => change("userEmail", event)}
        label={"דואר אלקטרוני"}
      />
      <TextInput
        value={userPhone}
        id={"userPhone"}
        touched={touched}
        errors={errors}
        onChange={(event) => change("userPhone", event)}
        label={"מספר טלפון"}
      />

      <Button
        className="submitButton"
        type="submit"
        variant="contained"
        disabled={!isValid}
      >
        שלח
      </Button>
    </form>
  );
};
