import React, { useContext } from "react";
import panaxiaLogo from "../../assets/images/PanaxiaLogo.svg";
import { progressMapping } from "./progressMapping";
import { headerTexts } from "../../texts";
import "./Header.scss";
import { useSelector } from "react-redux";

function Header(props) {
  const currentPage = useSelector(
    (state) => state.currentPageReducer.currentPage
  );

  return (
    <div
      className={
        progressMapping[currentPage] >= 1 ? "progressHeader" : "header"
      }
    >
      {progressMapping[currentPage] > 0 && (
        <div className="progressBar">
          <div
            className={`step1 ${
              progressMapping[currentPage] >= 1 && "addProgress"
            }
             ${progressMapping[currentPage] >= 2 && "done"}`}
          >
            <div className="progressBarText">
              <span className="progressStep">
                {headerTexts.progressBarstep1}
              </span>
              <span>{headerTexts.progressBarStep1Text}</span>
            </div>
            <div className="bar"></div>
          </div>
          <div
            className={`step2 ${
              progressMapping[currentPage] >= 2 && "addProgress"
            }
            ${progressMapping[currentPage] >= 3 && "done"}`}
          >
            <div className="progressBarText">
              <span className="progressStep">
                {headerTexts.progressBarStep2}
              </span>
              <span>{headerTexts.progressBarStep2Text}</span>
            </div>
            <div className="bar"></div>
          </div>
          <div
            className={`step3 ${
              progressMapping[currentPage] >= 3 && "addProgress"
            }
            ${progressMapping[currentPage] >= 4 && "done"}`}
          >
            <div className="progressBarText">
              <span className="progressStep">
                {headerTexts.progressBarStep3}
              </span>
              <span>{headerTexts.progressBarStep3Text}</span>
            </div>
            <div className="bar"></div>
          </div>
          <div
            className={`step4 ${
              progressMapping[currentPage] >= 4 && "addProgress"
            }`}
          >
            <div className="progressBarText">
              <span className="progressStep">
                {headerTexts.progressBarStep4}
              </span>
              <span>{headerTexts.progressBarStep4Text}</span>
            </div>
            <div className="bar"></div>
          </div>
        </div>
      )}
      <img alt="" src={panaxiaLogo} />
    </div>
  );
}

export default Header;
