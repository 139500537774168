import { createSlice } from "@reduxjs/toolkit";
import { WELCOME_PAGE } from "../../constants";

/**
 * "createSlice" wraps reducer function with produce from the Immer library.
 * This means you can write code that "mutates" the state inside the reducer,
 * and Immer will safely return a correct immutably updated result.
 */
const currentPageSlice = createSlice({
  name: "currentPage",
  initialState: {
    currentPage: WELCOME_PAGE,
  },
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
});

export const { setCurrentPage } = currentPageSlice.actions;
export default currentPageSlice.reducer;
