import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { questionAndAnswerText } from "../../texts";
import "./QuestionAndAnswer.scss";

function QuestionAndAnswer({ singleItem }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List className="qAndA">
      <ListItem className="qAndARow" button onClick={handleClick}>
        <ListItemIcon className="qHeader">
          {questionAndAnswerText.questionHeaderText}
        </ListItemIcon>
        <ListItemText className="question" primary={singleItem.q} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className="">
            <ListItemIcon className="aHeader">
              {questionAndAnswerText.answerHeaderText}
            </ListItemIcon>
            <ListItemText className="answer">
              <span dangerouslySetInnerHTML={{__html: singleItem.a}}></span>
              </ListItemText>
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}

export default QuestionAndAnswer;
