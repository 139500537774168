import React, { useEffect } from "react";
import Main from "../Main/Main";
import Gallery from "../Gallery/Gallery";
import Footer from "../Footer/Footer.jsx";
import Header from "../Header/Header";
import { fetchUserRequest } from "../../store/slices/userSlice";
import { connect } from "react-redux";

import "./Layout.scss";
import QuestionsAndAnswersWrapper from "../QuestionsAndAnswersWrapper/QuestionsAndAnswersWrapper";

function Layout({ fetchUserRequest, apiToken }) {
  useEffect(() => {
    if (apiToken) {
      fetchUserRequest();
    }
  }, []);

  return (
    <div className="layout">
      <QuestionsAndAnswersWrapper />
      <div className="layoutMain">
        <div className="layoutMainContent">
          <Header />
          <Main />
        </div>
        <Footer />
      </div>
      <div className="layoutGallery">
        <Gallery />
      </div>
    </div>
  );
}
const mapDispatchToProps = { fetchUserRequest };

const mapStateToProps = (state) => ({
  userDetails: state.userReducer,
  apiToken: state.userCredentialsReducer.apiToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
