import { createSlice } from "@reduxjs/toolkit";

/**
 * "createSlice" wraps reducer function with produce from the Immer library.
 * This means you can write code that "mutates" the state inside the reducer,
 * and Immer will safely return a correct immutably updated result.
 */
const userFinishProcessSlice = createSlice({
  name: "finishProcess",
  initialState: {
    isUserFinishedProcess: false,
    error: null,
  },
  reducers: {
    updateUserFinishProcessRequest(state, action) {},
    userFinishProcessSuccess(state, action) {
      state.isUserFinishedProcess = true;
    },
    userFinishProcessFail(state, action) {
      state.error = action.payload;
      state.isUserFinishedProcess = true;
    },
    resetError(state, action) {
      state.error = null;
       state.isUserFinishedProcess = false;
    },
  },
});

export const {
  updateUserFinishProcessRequest,
  userFinishProcessSuccess,
  userFinishProcessFail,
  resetError,
} = userFinishProcessSlice.actions;
export default userFinishProcessSlice.reducer;
