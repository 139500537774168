import React, { useState } from 'react';
import "./DigitalSignatureInput.scss";
import SignatureBox from './SignatureBox/SignatureBox';
import { Modal } from "../../Modal/Modal";


function DigitalSignatureInput(props) {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  }

  const closeModal = () => {
    setShowModal(false);
  }

  return (
    <div className="digitalSignatureInput">
      <div>
        <Modal show={showModal} handleClose={closeModal}>
          {showModal &&
            <SignatureBox
              value={props.value}
              onChange={props.onChange}
              onFinish={closeModal} />}
        </Modal>
        <button onClick={openModal} type="button" className="signatureModal">
          לחץ כאן על מנת לחתום
        </button>
      </div>
    </div>
  );
}

export default DigitalSignatureInput;