import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import "./ButtonGroupInput.scss";

function ButtonGroupForm(props) {
  const {
    value,
    values,
    id,
    touched,
    errors,
    onChange,
    label,
    className,
    required,
  } = props;

  const tooltipClasses = makeStyles((theme) => ({
    arrow: {
      color: "white",
    },
    tooltip: {
      font:
        "var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-12)/var(--line-height-22) var(--font-family-primary)",
      backgroundColor: "white",
      color: "#F2415C",
      boxShadow: "0px 3px 10px #00000029",
    },
  }))();

  return (
    <FormControl
      className={`buttonGroup ${
        touched[id] && !!errors[id] ? "hasError" : ""
      } ${className}`}
    >
      <label htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <ToggleButtonGroup
        size="small"
        className="toggleButtonGroup"
        exclusive
        onChange={(event, value) => onChange(value)}
      >
        {Object.keys(values).map((key) => (
          <ToggleButton
            className={`toggleButton ${value === values[key] && "selected"}`}
            selected={value === values[key]}
            value={values[key]}
            key={values[key]}
          >
            {key}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {touched[id] && !!errors[id] && (
        <div className="errorMessage">
          <Tooltip
            classes={tooltipClasses}
            arrow
            title={errors[id]}
            placement="top"
          >
            <span className="errorIcon">i</span>
          </Tooltip>
        </div>
      )}
    </FormControl>
  );
}

export default ButtonGroupForm;
