import React from "react";
import closeModalIcon from "../../../assets/images/deleteDocumentIcon.svg";
import "./LoginOrRegister.scss";

function IncorrectDetails(props) {

  const submit = () => {
    props.onFinish();
  };
  return (
    <div className="loginOrRegister">
      <div className="messageHeader">שגיאת התחברות</div>
      <div className="messageDetails">
        אירעה שגיאה
        <br />
        נא לנסות שנית
      </div>
      <button className="loginLink" type="button" onClick={submit}>
        נסה שוב
      </button>
      <button
        type="button"
        onClick={() => props.onFinish()}
        className="closeModalButton"
      >
        <img alt="" src={closeModalIcon} />
      </button>
    </div>
  );
}

export default IncorrectDetails;
