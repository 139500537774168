import React from "react";
import deleteDocumentIcon from "../../../../assets/images/deleteDocumentIcon.svg";
import successIcon from "../../../../assets/images/successIcon.svg";
import failureIcon from "../../../../assets/images/failureIcon.svg";
import { fileUploadResultTexts } from "../../../../texts";

function FileUploadResult({
  fileName,
  fileUploadStatus,
  deleteSelectedDocument,
  resendDocument,
}) {
  return (
    <div className="uploadResult" key={fileName}>
      <button
        className="deleteDocument"
        aria-label={fileUploadResultTexts.deleteButtonText}
        onClick={() => deleteSelectedDocument(fileName)}
        type="button"
      >
        <img className="deleteDocumentIcon" alt="" src={deleteDocumentIcon} />
      </button>

      <span>{fileName}</span>
      {fileUploadStatus.isUploadFinished &&
        (fileUploadStatus.isUploadSuccessesfull ? (
          <div className="successeded">
            <img className="successIcon" alt="" src={successIcon} />
          </div>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={(event) => {
              event.preventDefault();
              resendDocument(fileName);
            }}
            to="#"
            role="button"
            className="tryUploadAgain"
          >
            {fileUploadResultTexts.tryAgainText}
            <img className="failureIcon" alt="" src={failureIcon} />
          </a>
        ))}
    </div>
  );
}

export default FileUploadResult;
