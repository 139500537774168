import axios from "axios";
import { appConfig } from "./config";

export const login = (userId, userPhone) => {
  return axios.post(`${appConfig.apiBaseUrl}/api/auth/login/`, {
    social_number: userId,
    phone_number: userPhone,
  });
};

export const register = (userEmail, userPhone) => {
  return axios.post(`${appConfig.apiBaseUrl}/api/auth/register/`, {
    email: userEmail,
    phone_number: userPhone,
  });
};

export const verify = (userVerificationCode, userPhone) => {
  return axios.post(`${appConfig.apiBaseUrl}/api/auth/verify_otp/`, {
    otp_code: userVerificationCode,
    phone_number: userPhone,
  });
};

export const getUser = (userToken) => {
  return axios.get(`${appConfig.apiBaseUrl}/api/user-details/`, {
    headers: {
      Authorization: "JWT " + userToken,
    },
  });
};

export const updatePersonalData = (clientUser, userToken) => {
  return axios.put(
    `${appConfig.apiBaseUrl}/api/user-details/update_personal_data/`,
    clientUser,
    {
      headers: {
        Authorization: "JWT " + userToken,
      },
    }
  );
};

export const updateAdditionalData = (clientUser, userToken) => {
  return axios.put(
    `${appConfig.apiBaseUrl}/api/user-details/update_extra_data/`,
    clientUser,
    {
      headers: {
        Authorization: "JWT " + userToken,
      },
    }
  );
};

export const updateFinalData = (clientUser, userToken) => {
  return axios.put(
    `${appConfig.apiBaseUrl}/api/user-details/update_medical_data/`,
    clientUser,
    {
      headers: {
        Authorization: "JWT " + userToken,
      },
    }
  );
};

export const uploadSingleDocument = (
  file,
  category,
  onUploadProgressChange,
  userToken
) => {
  let config = {
    onUploadProgress: function (progressEvent) {
      onUploadProgressChange(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
    },
    headers: {
      Authorization: "JWT " + userToken,
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("file_name", file.name);
  formData.append("file_category", category);
  formData.append("file", file);
  return axios.post(
    `${appConfig.apiBaseUrl}/api/user-documents/`,
    formData,
    config
  );
};

export const deleteSingleDocument = (fileId, userToken) => {
  return axios({
    method: "delete",
    url: `${appConfig.apiBaseUrl}/api/user-documents/${fileId}/`,
    headers: { Authorization: "JWT " + userToken },
  });
};

export const getUploadedDocuments = (userToken) => {
  return axios.get(`${appConfig.apiBaseUrl}/api/user-documents/`, {
    headers: {
      Authorization: "JWT " + userToken,
    },
  });
};
