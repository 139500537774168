import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UPLOAD_DOCUMENT_PAGE } from "../../../constants";
import { routes } from "../../../routes";
import { useHistory } from "react-router-dom";
import UploadDocument from "../../FormElements/UploadDocument/UploadDocument";
import { resetError as userDetailsResetPrevState } from "../../../store/slices/userDetailsSlice";
import { uploadDocumentsPageTexts } from "../../../texts";
import { setCurrentPage } from "../../../store/slices/currentPageSlice";
import { fetchUploadedDocumentsRequest } from "../../../store/slices/userUploadDocumentsSlice";

import "./UploadDocumentsPage.scss";

function UploadDocumentsPage(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const uploadedDocumentsState = useSelector((state) => state.userUploadDocumentsReducer)
  const userCredentialsState = useSelector((state) => state.userCredentialsReducer)
  const [isLicenseSubmitted, setIsLicenseSubmitted] = useState(false);
  const [prescriptionsSubmitted, setIsPrescriptionsSubmitted] = useState(false);

  const skipDocumentsPage = userCredentialsState.isExistingCustomer; 

  useEffect(() => {
    let licenseSubmitted = false;
    let prescriptionSubmitted = false;
    if (uploadedDocumentsState) {
      for (const document in uploadedDocumentsState) {
        const documentData = (uploadedDocumentsState[document])
        if (documentData.category === "license" && documentData.isUploadFinished && documentData.isUploadSuccessesfull) {
          licenseSubmitted = true;
        }
        if (documentData.category === "prescriptions" && documentData.isUploadFinished && documentData.isUploadSuccessesfull) {
          prescriptionSubmitted = true;
        }
      }
    }
    setIsLicenseSubmitted(licenseSubmitted);
    setIsPrescriptionsSubmitted(prescriptionSubmitted);
  }, [uploadedDocumentsState])

  useEffect(() => {
    dispatch(setCurrentPage(UPLOAD_DOCUMENT_PAGE));
    dispatch(fetchUploadedDocumentsRequest());
    dispatch(userDetailsResetPrevState());
  }, []);

  const submitDocuments = () => {
    history.push(routes.additionalDetailsPage);
  }
  console.log(skipDocumentsPage)
  console.log(userCredentialsState)
  return (
    skipDocumentsPage ? <Redirect to={routes.additionalDetailsPage}/> : 
    <div className="uploadDocuments">
      <div className="uploadDocumentsText">
        {uploadDocumentsPageTexts.uploadDocumentsPageMainText}
      </div>
      <div className="uploadDocumentsExplanationText">
        {uploadDocumentsPageTexts.uploadDocumentsPageExplanationText}
      </div>
      <UploadDocument
        id="license"
        category="license"
        text={uploadDocumentsPageTexts.uploadDocumentsPageAddLicenceText}
      />
      <div className="uploadDocumentsSeparationLine"></div>
      <div className="actions">
        <Link to={routes.userDetailsPage} className="back">
          {uploadDocumentsPageTexts.uploadDocumentsPageGoBackLinkText}
        </Link>
        <button disabled={!isLicenseSubmitted} onClick={submitDocuments} type="button" className="submitOrBack">
          {uploadDocumentsPageTexts.uploadDocumentsPageContinueLinkText}
        </button>
      </div>
    </div>
  );
}

export default UploadDocumentsPage;
