import React, { useState, useRef } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/he";

import "./DateInput.scss";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const fromMonth = new Date(1900, 0);
const toMonth = new Date(currentYear, currentMonth);

function YearMonthCaption({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();
  const selectMonthRef = useRef(null);
  const selectYearRef = useRef(null);

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = () => {
    const year = selectYearRef.current;
    const month = selectMonthRef.current;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className="DayPicker-Caption">
      <select
        ref={selectMonthRef}
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
      >
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select
        ref={selectYearRef}
        name="year"
        onChange={handleChange}
        value={date.getFullYear()}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

function DateInput(props) {
  const {
    value,
    id,
    touched,
    errors,
    onChange,
    label,
    type,
    required,
    className,
  } = props;
  const [month, setMonth] = useState(toMonth);

  const handleYearMonthChange = (month) => {
    setMonth(month);
  };

  const tooltipClasses = makeStyles((theme) => ({
    arrow: {
      color: "white",
    },
    tooltip: {
      font:
        "var(--font-style-normal) normal var(--font-weight-normal) 12px/22px var(--font-family-primary)",
      backgroundColor: "white",
      color: "#F2415C",
      boxShadow: "0px 3px 10px #00000029",
    },
  }))();

  return (
    <FormControl
      className={`dateInput ${
        touched[id] && !!errors[id] ? "hasError" : ""
      } ${className}`}
    >
      <label htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <DayPickerInput
        classNames={{
          container: "MuiInputBase-root MuiInputBase-formControl",
          overlay: "DayPickerInput-Overlay rtlOverlay",
        }}
        format="DD/MM/YYYY"
        placeholder={"DD/MM/YYYY"}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          locale: "he",
          localeUtils: MomentLocaleUtils,
          captionElement: ({ date, localeUtils }) => (
            <YearMonthCaption
              date={date}
              localeUtils={localeUtils}
              onChange={handleYearMonthChange}
            />
          ),
          month,
          fromMonth,
          toMonth,
        }}
        type={type}
        value={value}
        onDayChange={(selectedDay, modifiers, dayPickerInput) =>
          onChange(dayPickerInput.getInput().value)
        }
        id={id}
      />
      {touched[id] && !!errors[id] && (
        <div className="errorMessage">
          <Tooltip
            classes={tooltipClasses}
            arrow
            title={errors[id]}
            placement="top"
          >
            <span className="errorIcon">i</span>
          </Tooltip>
        </div>
      )}
    </FormControl>
  );
}

export default DateInput;
