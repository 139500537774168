import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FINISH_PROCESS_PAGE } from "../../../constants";
import { Formik } from "formik";
import { FinishProcessSchema } from "./finishProcessSchema";
import FinishProcessForm from "./FinishProcessForm/FinishProcessForm";
import { finishProcessPageTexts } from "../../../texts";
import { useHistory } from "react-router-dom";
import { updateUserFinishProcessRequest } from "../../../store/slices/userFinishProcessSlice";
import { resetError as userAdditionalDetailsResetPrevState } from "../../../store/slices/userAdditionalDetailsSlice";
import { routes } from "../../../routes";
import { setCurrentPage } from "../../../store/slices/currentPageSlice";

import "./FinishProcessPage.scss";

function FinishProcessPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userReducer);
  const isUserFinishedProcess = useSelector(
    (state) => state.userFinishProcessReducer.isUserFinishedProcess
  );
  const error = useSelector((state) => state.userFinishProcessReducer.error);

  useEffect(() => {
    dispatch(setCurrentPage(FINISH_PROCESS_PAGE));
    dispatch(userAdditionalDetailsResetPrevState());
  }, []);

  useEffect(() => {
    if (isUserFinishedProcess) {
      if (error) {
        history.push(routes.errorNotification);
      } else {
        localStorage.removeItem('panaxiaUserToken');
        history.push(routes.successNotification);
      }
    }
  }, [isUserFinishedProcess, error, history]);

  const submit = (values) => {
    dispatch(updateUserFinishProcessRequest(values));
  };

  return (
    <div className="finishProcessPage">
      <div className="finishProcessAndDeliveryText">
        {finishProcessPageTexts.finishProcessPageMainText}
      </div>
      <Formik
        onSubmit={submit}
        children={(props) => <FinishProcessForm {...props} />}
        initialValues={userDetails}
        enableReinitialize
        validationSchema={FinishProcessSchema}
      />
    </div>
  );
}
export default FinishProcessPage;
