import React, { useRef, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { MenuList, MenuItem, Paper, Grid, InputBase } from "@material-ui/core";
import "./LocationSearchInput.scss";

function LocationSearchInput(props) {
  const {
    value,
    id,
    touched,
    errors,
    onChange,
    label,
    required,
    className,
    disabled,
  } = props;

  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const tooltipClasses = makeStyles((theme) => ({
    arrow: {
      color: "white",
    },
    tooltip: {
      font:
        "var(--font-style-normal) normal var(--font-weight-normal) 12px/22px var(--font-family-primary)",
      backgroundColor: "white",
      color: "#F2415C",
      boxShadow: "0px 3px 10px #00000029",
    },
  }))();

  let queryKey = "שם_ישוב";

  useEffect(() => {
    if (!inputValue) {
      return;
    }
    let query = { [queryKey]: inputValue.replace(/\s/g, "%") + ":*" };
    fetch(
      `https://data.gov.il/api/3/action/datastore_search?resource_id=5c78e9fa-c2e2-4771-93ff-7f400a12f7ba&limit=20&offset=0&fields=${encodeURIComponent(
        queryKey
      )}&distinct=true&sort=${encodeURIComponent(
        queryKey
      )}&include_total=false&plain=false&q=${encodeURIComponent(
        JSON.stringify(query)
      )}`,
      {
        headers: {
          accept: "application/json",
        },
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((body) => setOptions(body.result.records));
  }, [inputValue]);

  const inputClasses = makeStyles((theme) => ({
    root: {
      background: "#FFFFFF 0% 0 % no - repeat padding- box",
      border: "1px solid var(--color-inactive)",
      borderRradius: "3px",
      opacity: "1",
      width: "100%",
      height: "2.65rem",
      padding: "0!important",
      font:
        "var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-14) /var(--line-spacing-30) var(--font-family-primary)",
    },
    input: {
      padding: "0.75rem",
      height: "1rem",
    },
  }))();

  return (
    <FormControl
      className={`locationSearchInput ${
        touched[id] && !!errors[id] ? "hasError" : ""
      } ${className} ${disabled ? "disabled" : ""} `}
    >
      <label htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
      </label>

      <Autocomplete
        id={id}
        // style={{ width: 300 }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option[queryKey]
        }
        options={options}
        autoComplete
        value={value}
        onChange={(event, selectedOption) => {
          onChange(selectedOption ? selectedOption[queryKey].trim() : "");
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue.trim());
        }}
        getOptionSelected={(option, value) => option[queryKey].trim() === value}
        renderInput={(params) => (
          <InputBase
            classes={inputClasses}
            {...params.InputProps}
            inputProps={params.inputProps}
            onChange={(event) => setInputValue(event.target.value)}
          />
        )}
        renderOption={(option) => {
          return (
            <Grid container alignItems="center">
              <Grid item xs>
                {option[queryKey]}
              </Grid>
            </Grid>
          );
        }}
      />

      {touched[id] && !!errors[id] && (
        <div className="errorMessage">
          <Tooltip
            classes={tooltipClasses}
            arrow
            title={errors[id]}
            placement="top"
          >
            <span className="errorIcon">i</span>
          </Tooltip>
        </div>
      )}
    </FormControl>
  );
}

export default LocationSearchInput;
