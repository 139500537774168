import { createSlice } from "@reduxjs/toolkit";

/**
 * "createSlice" wraps reducer function with produce from the Immer library.
 * This means you can write code that "mutates" the state inside the reducer,
 * and Immer will safely return a correct immutably updated result.
 */
const userAdditionalDetailsSlice = createSlice({
  name: "additionalDetails",
  initialState: {
    isUserAdditionalDetailsFinished: false,
    error: null,
  },
  reducers: {
    updateUserAdditionalDetailsRequest(state, action) {},
    userAdditionalDetailsSuccess(state, action) {
      state.isUserAdditionalDetailsFinished = true;
    },
    userAdditionalDetailsFail(state, action) {
      state.error = action.payload;
      state.isUserAdditionalDetailsFinished = true;
    },
    resetError(state, action) {
      state.error = null;
      state.isUserAdditionalDetailsFinished = false;
    },
  },
});

export const {
  updateUserAdditionalDetailsRequest,
  userAdditionalDetailsSuccess,
  userAdditionalDetailsFail,
  resetError,
} = userAdditionalDetailsSlice.actions;
export default userAdditionalDetailsSlice.reducer;
