import React from "react";

import "./PolicyText.scss";

function PolicyText(props) {
  return (
    <div className="policyText">
      <p>
        אנחנו מעריכים את פרטיותך ופועלים כדי לשמור עליה, ולכן במדיניות פרטיות זו
        ("מדיניות הפרטיות"), נתאר כיצד אנחנו עושים ונעשה מאמצים כדי לשמור על
        הפרטיות שלך ועל הסודיות הרפואית שלך באמצעות הגבלת האיסוף, האגירה והשימוש
        במידע שמתקבל בעת השימוש שלך בפלטפורמה (כהגדרתה{" "}
        <span className="useTerms">בתנאי השימוש</span>) ומתן השירותים (כהגדרתם{" "}
        <span className="useTerms">בתנאי השימוש</span>).
      </p>
      <p>
        מדיניות פרטיות זו גובשה תוך מחשבה רבה על שמירה על פרטיותך בעת השימוש
        בפלטפורמה.
      </p>
      <p>
        מדיניות הפרטיות היא חלק מתנאי השימוש של הפלטפורמה, ועל-ידי התקנה, גישה
        או שימוש בפלטפורמה, או בשירותים, אתה מצהיר ומאשר כי קראת את{" "}
        <span className="useTerms">תנאי השימוש</span> ומדיניות הפרטיות ואתה מודע
        ומסכים כי כל מידע שנמסר באמצעות הפלטפורמה, לרבות מידע אישי או רפואי,
        נמסר בהתאם לרצונך החופשי, למדיניות פרטיות זאת וכן לנוהלי איסוף המידע
        והשימוש בו שמתוארים במדיניות פרטיות זו. אם אינך מסכים לאמור במדיניות
        פרטיות זו, הינך מתבקש שלא לעשות שימוש בפלטפורמה.
      </p>
      {/* <p> */}
      <ol>
        <li>
          <strong>
            <u>מידע בו יעשה שימוש לצורך מתן שירות עבורך</u>
          </strong>
        </li>

        <p>
          כדי להשתמש בצורה נאותה ותקינה בפלטפורמה ובשירותים, עליך לספק מידע
          (לרבות מידע אישי ומידע רפואי, כהגדרת מונחים אלה במדיניות פרטיות זו).{" "}
          <strong>
            כל מידע יימסר על ידך מרצונך ולפי שיקול-דעתך. אינך חייב על-פי החוק
            למסור מידע, אולם אם תבחר שלא למסור את המידע, לא תוכל להשתמש
            בפלטפורמה או בשירותים.{" "}
          </strong>
        </p>
        <p>
          המידע אותו תתבקש למסור יכול להשתנות בהתאם לסוג השירותים שתבחר לקבל
          והפעולות שתעשה בפלטפורמה, אך יכלול, ככלל, את סוגי המידע הבאים
          ("המידע"):
        </p>
        {/* <p> */}
        <ul>
          <li>
            <strong>מידע אישי</strong> – מידע בעל אופי פרטי או רגיש או מידע
            המאפשר זיהוי פרטני של אדם או עשוי במאמץ סביר (לבד או בשילוב עם מידע
            אחר), לזהות אדם ("מידע אישי"). מידע אישי יכול לכלול, למשל, שם פרטי,
            שם משפחה, מידע ומיקום גאוגרפי, כתובת מגורים, מספר טלפון, דואר
            אלקטרוני, פרטי תשלום, מידע על הזמנות קודמות, כרטיס משתמש בפלטפורמה
            ו-'מידע רגיש', כהגדרתו בחוק הגנת הפרטיות, התשמ"א-1981 ("חוק
            הפרטיות").
          </li>
          <li>
            <strong>מידע לא אישי</strong> – הוא מידע ללא התייחסות מסוימת לזהות
            המשתמש ממנו נאסף המידע, כך שלא ניתן באמצעותו לזהות משתמש ספציפי
            ("מידע לא אישי"). מידע לא אישי יכול לכלול, למשל, מאפיינים דמוגרפיים,
            מידע אודות אופן השימוש בפלטפורמה (כמו, סוג מכשיר המשתמש, מערכת
            ההפעלה, סוג וגרסת הדפדפן, רזולוציית המסך, שפת הדפדפן והמקלדת, 'זרם
            הקלקה', אופי פעולות ומשך הזמן של שימוש בשירותים וחותמות זמן קשורות)
            ועיבוד של מידע לאחר הסרת המידע האישי.
          </li>
          <li>
            <strong>מידע רפואי</strong> – מידע זה נחשב מידע רגיש בחוק הפרטיות
            והוא כולל 'מידע רפואי' כהגדרתו בחוק זכויות החולה, תשנ"ו-1996 ("מידע
            רפואי"). מידע רפואי יכלול את מצבך הרפואי כפי שתמסור לנו, המרשם
            הרפואי שתעלה לפלטפורמה והמידע הכלול בו (למשל: אבחון, טיפול, בדיקה,
            ייעוץ), מידע היסטורי וכל מידע רפואי אחר הקשור לשירותים. מידע זה
            ייחשב מידע אישי.
          </li>
          <li>
            מידע מועדון הלקוחות – ככל שתבחר להצטרף למועדון הלקוחות שלנו (בהתאם
            לקבוע בתנאי השימוש), נבקש שתמסור לנו מידע אישי ומידע רפואי נוסף,
            לרבות המידע הבא: תאריך לידה, מין, סיבות לשימוש במוצרי החברה, זנים
            מועדפים, העדפות לקבלת משלוח, צורך בייעוץ רוקחי וכן העדפות, המלצות,
            ומידע נוסף הקשור לחווייתך מהשימוש במוצרי ושירותי החברה. רישום
            למועדון הלקוחות נתון לבחירתך.
          </li>
        </ul>
        {/* </p> */}
        <p>
          מסירת המידע מצידך מהווה הצהרה בנוגע לנכונותו. מסירת מידע שגוי או אי
          מסירת מלוא המידע הנדרש עלול למנוע ממך את האפשרות להשתמש בפלטפורמה או
          להשתמש בשירותים. אם חל שינוי בפרטיך, עליך לעדכן אותם בהקדם באמצעות
          הפלטפורמה או באמצעות פנייה אלינו.
        </p>
        <li>
          <strong>
            <u>אופן השימוש במידע שסיפקת לנו </u>
          </strong>
        </li>
        <p>
          השימוש, האיסוף והשמירה של המידע האישי על ידינו ייעשה על-פי מדיניות
          פרטיות זו לצרכי מתן ואספקת השירותים ותמיכה למשתמשים ולצרכי תפעול
          ותפקוד הפלטפורמה. כמו-כן, על-פי הוראות הדין החל, על מנת לזהות ולמנוע
          שימושים אסורים בפלטפורמה, וירוסים, הונאות או הפרה של תנאי השימוש או כל
          דין; ו/או לכל מטרה חוקית אחרת.
        </p>
        <p>
          מידע מועדון הלקוחות נאסף על ידנו למטרות שיפור שירות, שיפור הייעוץ
          הרוקחי, הצעת מבצעים והנחות מותאמי חבר מועדון הלקוחות, ההטבות, שליחת
          מתנות ושירותים נוספים לטובת חברי מועדון הלקוחות שלנו.
        </p>
        <p>
          המידע יישמר במאגר המידע הרשום שלנו בהתאם לסטנדרטים הגבוהים המקובלים
          בתעשייה ובהתאם לכל דין. המידע האישי יישמר על ידנו כל עוד השירותים
          ניתנים או כל עוד אתה רשום לפלטפורמה, וכן לאחר מכן, עד לתום תקופת
          ההתיישנות הרלוונטית, או לתקופה מאוחרת יותר, בהתאם לדין החל.
        </p>
        <p>
          בתום תקופה זו, ובהתאם לדין החל, המידע האישי יימחק ממאגר המידע שלנו.
          אין במחיקה זו כדי להשפיע על מידע שהועבר לצדדים שלישיים כמתואר במדיניות
          פרטיות זו.
        </p>
        <p>
          ובהתאם לקבוע בחוק, אנו נבצע תיעוד של כל המשלוחים שבוצעו בפנקס המשלוחים
          שלנו, תיעוד זה יכלול מידע רפואי (למשל, המרשם המקורי והתכשירים שנופקו)
          וכן מידע אישי נוסף. מידע זה יישמר על ידנו עד לתקופה הקבועה בחוק
        </p>
        <p>
          מעבר לאמור לעיל, נהיה רשאים להמשיך לשמור ולהשתמש בעותקים של מידע לא
          אישי, לרבות מידע סטטיסטי ומידע מצרפי, מידע כוללני, או מידע אישי שעבר
          התממה, על מנת לאפשר לנו לפתח, לנתח, לשפר, להעשיר, לתפעל ולשנות את
          הפלטפורמה, השירותים ואת היכולות הטכנולוגיות שלנו, לבצע ניתוח סטטיסטי,
          לצורכי מחקר ופיתוח, לבחון ולפתח שירותים נוספים או מוצרים נוספים ולספק
          תמיכה ושירותים אחרים לטובת המשתמשים.
        </p>
        <li>
          <strong>
            <u>אמצעים בהם נעשה שימוש לאיסוף מידע לא אישי </u>
          </strong>
        </li>
        <p>
          אנחנו משתמשים בטכנולוגיות לאיסוף מידע לא אישי באופן אוטומטי.
          טכנולוגיות אלה משמשות לתחזוקה, אספקה ושיפור השירותים ופעילות הפלטפורמה
          באופן שוטף ועל מנת לספק למשתמשים חוויה טובה יותר. כמו-כן, טכנולוגיות
          כאלה מאפשרות לנו לבדוק את העדפות המשתמשים ואופן השימוש בפלטפורמה,
          לאבטח את השירותים בצורה טובה יותר, לזהות סוגיות טכניות, לפקח ולשפר את
          הביצועים הכוללים של השירותים, להפיק לקחים ולפתח שירותים נוספים.{" "}
        </p>
        <p>
          על מנת שחלק מהטכנולוגיות הללו יפעלו כראוי, יש להוריד קובץ נתונים קטן,
          כגון "עוגיות" (cookies), שיתאכסן במכשירך. אם אתה מעדיף לא לקבל
          cookies, מרבית הדפדפנים והמכשירים יאפשרו לך להתאים את ההגדרות שלך כדי
          להודיע לך כשתקבל אותן, לדחות אותן אוטומטית או להשבית את הקיימות. תלוי
          במכשיר הנייד ובמערכת ההפעלה שלך, יתכן שלא תוכל לחסום ולמחוק כל cookie.
          לתשומת ליבך, מחיקת או השבתת קבצי cookies או אמצעי מעקב אחרים של
          טכנולוגיות עתידיות, עלולים למנוע ממך גישה לאזורים או תכונות מסוימים
          בפלטפורמה, או עלולים להשפיע לרעה על חווית המשתמש שלך. במקרה שאתה לא
          מעוניין בהפעלת הטכנולוגיות הללו, אנא שנה את ההגדרות במכשירך בהתאם.
        </p>
        <p>במסגרת השירותים, אנו משתמשים בקבצי נתונים קטנים (cookies) הבאים:</p>
        <ul>
          <li>
            WooCommerce – לצורך שיפור הממשק עבור כל משתמש, הצעת שירותים מתאימים
            נוספים וכן איסוף מידע סטטיסטי לטובת שיפור הפלטפורמה והשירותים. למידע
            נוסף לגבי סוג הקבצים, מטרתם ומשך שמירת המידע שנאסף בעזרתם:{" "}
            <a
              href="https://docs.woocommerce.com/document/woocommerce-cookies/"
              className="useTerms"
            >
              WooCommerce Cookies
            </a>
            ;
          </li>
          <li>
            WordPress Session Cookies שמטרתם לוודא זהות משתמש, לוודא שימוש
            בcookies כדי לאפשר חווית שימוש מיטבית בפלטפורמה ולהתאים את ממשק
            הפלטפורמה לממשק המשתמש בהתאם לבחירה, העדפה והתאמה אישית. למידע נוסף
            לגבי סוג הקבצים, מטרתם ומשך שמירת המידע שנאסף בעזרתם:
            <a
              href="https://wordpress.org/support/article/cookies/"
              className="useTerms"
            >
              WordPress Cookies
            </a>
            .
          </li>
          <li>
            כלי "Google Analytics" לשם איסוף נתונים סטטיסטים (ללא נתונים אישיים)
            על תנועות בפלטפורמה לצורך שיפור הפלטפורמה והשירותים. למידע נוסף לגבי
            סוג הקבצים, מטרתם ומשך שמירת המידע שנאסף בעזרתם:
            <a
              href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
              className="useTerms"
            >
              Google Analytics Cookies
            </a>
            .
          </li>
          <p>
            בנוסף, אנו עשויים להשתמש בשירותים של צדדים שלישיים כדי לאסוף ולנתח
            מידע לא אישי הקשור עם השימוש בפלטפורמה או בשירותים, למשל לצורך ניתוח
            התעבורה בפלטפורמה, מחקר ובקרה.
          </p>
        </ul>
        <li>
          <strong>
            <u>הצדדים השלישיים שיקבלו את המידע </u>
          </strong>
        </li>
        <p>
          אנו עשויים לשתף את המידע שאנחנו אוספים, כאמור במדיניות פרטיות זו, עם
          צדדים שלישיים ברחבי העולם בהם אנו מסתייעים ומשתמשים למתן השירותים.
          בטרם התקשרות עם צד שלישי כלשהו, אנו נבחן את סיכוני אבטחת המידע הכרוכים
          בהתקשרות עם צד שלישי זה ונגדיר את התחייבויותיו כלפינו וכלפי המידע
          מבחינת אבטחה וסודיות. במקביל, אנו ננקוט אמצעי בקרה ופיקוח על הצדדים
          השלישיים מתוך מטרה לעמוד בהתחייבויותינו זו. על אף שנעשה מאמצים בטרם
          ההתקשרות עם צדדים שלישיים, יובהר כי אין ביכולתנו להבטיח כי צדדים
          שלישיים עימם נתקשר ואשר אליהם יועבר המידע יעמדו בהתחייבויותיהם.
        </p>
        <p>
          הצדדים איתם התקשרנו לצורך קבלת שירות המצריך מתן גישה למידע שאנו
          אוספים, נכון למועד זה, הם:
        </p>
        <ul>
          <li>
            משרד הבריאות, בכפוף לקבוע לפי כל דין, בשביל לעדכן את הרשומה הרפואית
            שלך, לבדוק את המרשם שבחרת להעלות לפלטפורמה ולספק לך את השירותים;
          </li>
          <li>
            PickPack – לצורך מעקב אחרי השליחות שבדרכה אליך. מידע נוסף לגבי
            מדיניות הפרטיות של PickPack{" "}
            <a
              href="https://www.pickpack.co.il/IL/Home.html"
              className="useTerms"
            >
              זמין באתר PickPack
            </a>
            ;
          </li>
          <li>
            Icount – המשמש אותנו כאמצעי סליקה והעברת התשלום. מידע נוסף לגבי
            מדיניות הפרטיות של Icount{" "}
            <a
              href="https://www.icount.co.il/privacy-policy/"
              className="useTerms"
            >
              זמין באתר Icount
            </a>
            ;
          </li>
          <li>
            צדדים שלישיים המסייעים לנו בתפעול הפלטפורמה, כמו MedaTech. מידע נוסף
            לגבי מדיניות הפרטיות של MedaTech{" "}
            <a
              href="https://medatech.com/wp-content/uploads/2018/05/CIPD0036-Privacy-Policy.pdf"
              className="useTerms"
            >
              זמין באתר MedaTech
            </a>
            .
          </li>
        </ul>
        <p>
          בנוסף, נהיה רשאים לחלוק את המידע, במידתיות ובזהירות, ובמקרה הצורך
          בלבד, גם במצבים הבאים:
        </p>
        <ul>
          <li>על מנת לעמוד בדרישות כל דין;</li>
          <li>
            בכל מקרה של מחלוקת, או הליך משפטי מכל סוג בינך לביננו ביחס לפלטפורמה
            או לשימושך בה או לשירותים, אנו נוכל לחשוף את המידע לצדדים שלישיים אם
            נאמין כי החשיפה היא בהתאם לחוק, לרגולציה או להליך משפטי, או נדרשת
            לפי חוק, רגולציה או הליך משפטי, או אם נאמין כי אתה מפר או כי עלינו
            לאכוף את <span className="useTerms">תנאי השימוש</span> או מדיניות
            הפרטיות;
          </li>
          <li>כדי לגבות ממך כספים בגין רכישת מוצר או שירותים;</li>
          <li>
            על מנת לגלות, למנוע או לטפל בכל אופן בהפרות של תנאי השימוש או
            מדיניות הפרטיות;
          </li>
          <li>על מנת להשיב לבקשות תמיכה של משתמשים;</li>
          <li>
            על מנת לטפל בסוגיות אבטחה ובטחון או בבעיות טכניות של הפלטפורמה;
          </li>
          <li>
            אם נרכש או נתמזג עם חברה אחרת, או נבצע העברה של הנכסים שלנו לחברה
            אחרת, אנו נעביר את המידע שלך לחברה האחרת ובתנאי שחברה אחרת זו תקבל
            על עצמה את הוראות מדיניות פרטיות זו;
          </li>
          <li>
            מידע לא אישי, לרבות מידע סטטיסטי ומידע מצרפי, מידע כוללני, או מידע
            אישי שעבר התממה, עם נותני שירותים הקשורים בפיתוח, ניתוח, שיפור,
            העשרה, תפעול ושינוי הפלטפורמה, השירותים, והיכולות הטכנולוגיות שלנו,
            ביצוע ניתוח סטטיסטי, בחינת ופיתוח שירותים נוספים, ומתן תמיכה
            ושירותים אחרים לטובת המשתמשים, וכן עם צדדים שלישיים נוספים, בהתאם
            לשיקול דעתנו וללא חשיפה של מידע אישי, לרבות עם חברות קשורות בקבוצת
            פנאקסיה או חברות שיימנו על קבוצת פנאקסיה בעתיד;
          </li>
          <li>
            בהתאם ובכפוף לקבוע בחוק זכויות החולה, נהיה רשאים למסור את המידע
            הרפואי לאחר, גם אם לא נתת את הסכמתך למסירתו, במידה הנדרשת לצורך
            העניין ותוך הימנעות מרבית מחשיפת זהותו של המטופל, בכל אחד מהמקרים
            הבאים: (א) חלה על פי דין חובה למסור את המידע הרפואי; (ב) מסירת המידע
            הרפואי היא למטפל אחר לצורך טיפול במטופל; (ג) מידע רפואי אשר לא נמסר
            לך לפי סעיף 18(ג) לחוק זכויות החולה וועדת האתיקה אישרה את מסירתו
            לאחר; (ד) ועדת האתיקה קבעה, לאחר מתן הזדמנות למטופל להשמיע את דבריו,
            כי מסירת המידע הרפואי על אודותיו חיונית להגנה על בריאות הזולת או
            הציבור וכי הצורך במסירתו עדיף מן העניין שיש באי מסירתו; (ה) מסירת
            המידע הרפואי היא למוסד הרפואי המטפל או לעובד של אותו מוסד רפואי
            לצורך עיבוד המידע, תיוקו או דיווח עליו על פי דין; (ו) מסירת המידע
            הרפואי נועדה לפרסום בבטאון מדעי, למטרות מחקר או הוראה בהתאם להוראות
            שקבע השר ובלבד שלא נחשפו פרטים מזהים של המטופל.
          </li>
        </ul>
        <li>
          <strong>
            <u>שימוש במידע לצורך שיווק וקבלת פרסומות</u>
          </strong>
        </li>
        <p>
          בהתאם לאישורך ורק אם נתת את אישורך לכך, אנו נעשה שימוש בפרטים שלך כדי
          לשלוח לך עדכונים, חומרים ומידע פרסומי או שיווקי. אם אתה מעוניין לחזור
          בך מהסכמתך לשימוש במידע שלך, אנא פנה אלינו. אנו מאמינים כי קבלת הודעות
          ועדכונים מאיתנו תשמש אותך על מנת להתעדכן במלאי הקיים, לגלות מוצאים
          חלופיים ותסייע לך במקרה ששכחת לבצע או לסיים הזמנה. לנוחיותך, בכל דבר
          פרסומת שנשלח אליך נכלול בצורה ברורה הוראות כיצד תוכל לחזור בך מהסכמתך
          ולשלוח לנו הודעת סירוב.
        </p>
        <li>
          <strong>
            <u>אבטחת המידע</u>
          </strong>
        </li>
        <p>
          אנחנו מטמיעים ומיישמים אמצעי זהירות ואבטחה ברמה גבוהה בהתאם לנהלים
          ולסטנדרטים הנהוגים והמקובלים ולחוקים החלים כדי להגן על המידע שאנחנו
          אוספים מפני אובדן, שימוש לרעה, גישה לא מורשית, גילוי ושינוי. אמצעי
          זהירות אלו נבדקים באופן שוטף מתוך מטרה לשפר את האמצעים לשמור על המידע.
          לתשומת ליבך, כי למרות מיטב המאמצים שאנחנו עושים ונמשיך לעשות, שום
          אמצעי אבטחת מידע לא יכול להבטיח אבטחה מוחלטת על המידע המועבר אל
          הפלטפורמה או באמצעותה, והעברת מידע היא באחריותך בלבד. כדי לשמור על
          בטיחות המידע שלך, אנו ממליצים לך לשמור את פרטי ההתחברות שלך בסודיות,
          לא לחשוף אותם לצדדים שלישיים וכן לצאת מהחשבון שלך לאחר תום השימוש.
        </p>
        <li>
          <strong>
            <u>איך אתה יכול לשלוט על המידע שלך</u>
          </strong>
        </li>
        <p>
          אנו רוצים לאפשר לך לשלוט במידע שלך ככל שניתן, ונעשה את כל המאמצים כדי
          לאפשר לך לעדכן או לתקן את המידע שמסרת לנו, להשבית או למחוק את חשבונך,
          וכן לבקש גישה, להגביל או לקבל מידע על עיבוד המידע האישי שלך. אם ברצונך
          לעשות כן, <span className="useTerms">אנא פנה אלינו</span>. בנוסף, אתה
          רשאי בכל עת לבקש כי המידע האישי שמסרת לנו יימחק ואנו נפעל על מנת
          להיענות לבקשתך, בכפוף לדין החל ובלבד שאיננו מחויבים על פי דין לשמור את
          המידע. אם ברצונך לעשות כן,{" "}
          <span className="useTerms">אנא פנה אלינו</span>. בהתאם לדין החל, אנו
          עשויים לשמור מידע שתוקן או שנמחק למטרות ארכיון. אין ביכולתנו לעדכן את
          המידע או למחוק אותו ממאגרי מידע של צדדים שלישיים (למשל ממאגר המידע של
          משרד הבריאות) ולצורך עדכון או מחיקה כאמור אנא פנה לגורם המצוין
          במדיניות הפרטיות של הגוף הרלוונטי. אם אתה זקוק לעזרה כדי לאתר מידע זה,
          אתה יכול <span className="useTerms">לפנות אלינו</span>. לתשומת ליבך כי
          ייתכן שהמכשיר הנייד בו אתה משתמש יספק כלים נוספים המאפשרים לך לשלוט
          מתי המכשיר שלך מספק קטגוריות מידע ספציפיות (לדוגמה, נתוני מיקום
          גיאוגרפי או שימוש בעוגיות). נא היה מודע לכלים אלה.
        </p>
        <li>
          <strong>
            <u>ציות לתקנות הפרטיות של ארה"ב והאיחוד האירופי</u>
          </strong>
        </li>
        <p>
          הפלטפורמה מיועדת לשימוש לתושבי מדינת ישראל בלבד, ולפיכך, אנו לא מחילים
          על הפעילות של הפלטפורמה את הוראות דיני הפרטיות של מדינת ארה"ב או של
          האיחוד האירופי. בהתאם, כל מידע אישי שתמסור יהיה מוגן לפי הדין הישראלי
          בלבד. יתר על כן, הפלטפורמה לא מהווה מכשיר רפואי, ולפיכך לא מוסדרת
          כציוד רפואי על פי הרגולציה הישראלית או האמריקאית.
        </p>
        <li>
          <strong>
            <u>שונות</u>
          </strong>
        </li>
        <p>
          כאמור <span className="useTerms">בתנאי השימוש</span> הפלטפורמה לא
          מיועדת לאנשים מתחת לגיל 18, למעט משתמשים מאושרים אשר שימושם במערכת
          הינו בהתאם לייפוי כוח, כאמור בתנאי השימוש. לא נאסוף ביודעין מידע על
          קטינים שאינם משתמשים מאושרים. אם ייוודע לנו כי קיבלנו בשוגג מידע אישי
          מקטינים אשר אינם משתמשים מאושרים, אנו נמחק מידע זה מהרשומות שלנו.
          מונחים שלא מוגדרים במדיניות פרטיות זו הם בהתאם להגדרתם{" "}
          <span className="useTerms">בתנאי השימוש</span>. מדיניות פרטיות זו
          נכתבה על בסיס הדין הרלוונטי בעת כתיבתה. אנו נעשה מאמצעים כדי להתאים את
          מדיניות הפרטיות והאופן שבו אנחנו אוספים ושומרים מידע לשינויי חקיקה
          בתחום. יובהר כי אין במדיניות פרטיות זו כוונה לאפשר לבצע פעולות שהדין
          אוסר. כל שינוי מהותי למדיניות הפרטיות יפורסם באתר שלנו. אנו ממליצים לך
          לעקוב אחרי האתר שכן מדיניות הפרטיות עשויה להתעדכן מעת לעת, לפי שיקול
          דעתנו וללא הודעה מראש, ומדיניות הפרטיות המעודכנת תחייב מרגע פרסומה.
          במקרה של כל תקלה או שאלה בקשר לשימוש בפלטפורמה, בשירותים או בקשר
          למדיניות הפרטיות, נשמח לנסות לעזור, אנא{" "}
          <span className="useTerms">פנה אלינו</span>.
        </p>
      </ol>
      {/* </p> */}
      <p>עודכן לאחרונה: ספטמבר, 2020</p>
    </div>
  );
}

export default PolicyText;
