import React from 'react';
import "./FileUploadProgressBar.scss";

function FileUploadProgressBar(props) {
  return (
    <div className="progressBar">
      <div className="progressDetials">
        <div>אנא המתן…</div>
        <div className="uploadPercent">{props.progress}%</div>
        <div>{props.fileNumber}/{props.totalFiles}</div>
      </div>
      <div className="barContainer">
        <div style={{ width: `${props.progress}%` }} className="progressInBar"></div>
      </div>
    </div>
  );
}

export default FileUploadProgressBar;