import React from 'react';
import { useHistory } from 'react-router-dom';
import closeModalIcon from "../../../assets/images/deleteDocumentIcon.svg";
import "./IncorrectDetails.scss";

function IncorrectDetails(props) {

  const history = useHistory();

  const submit = () => {
    props.onFinish();
  }
  return (
    <div className="incorrectDetails">
      <div className="messageHeader">שגיאת התחברות</div>
      <div className="messageDetails">אחד הנתונים שהכנסת שגוי, <br />נא לנסות שנית</div>
      <button className="loginLink" type="button" onClick={submit}>לקוח רשום</button>
      <button type="button" onClick={() => props.onFinish()} className="closeModalButton">
        <img alt="" src={closeModalIcon} />
      </button>
    </div>
  );
}

export default IncorrectDetails;