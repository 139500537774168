import { createSlice } from "@reduxjs/toolkit";

const faqSlice = createSlice({
  name: "faq",
  initialState: {
    isFaqShown: false,
  },
  reducers: {
    showFaq(state, action) {
      state.isFaqShown = true;
    },
    hideFaq(state, action) {
      state.isFaqShown = false;
    },
  },
});

export const { showFaq, hideFaq } = faqSlice.actions;
export default faqSlice.reducer;
