import { createSlice } from "@reduxjs/toolkit";


const apiTokenKey = "panaxiaUserToken";

/**
 * "createSlice" wraps reducer function with produce from the Immer library.
 * This means you can write code that "mutates" the state inside the reducer,
 * and Immer will safely return a correct immutably updated result.
 */
const userCredentialsSlice = createSlice({
  name: "credentials",
  initialState: JSON.parse(localStorage.getItem(apiTokenKey) || "{}"),
  reducers: {
    updateCredentials(state, action) {
      Object.assign(state, action.payload);
      localStorage.setItem(apiTokenKey, JSON.stringify(state));
    },
  },
});

export const { updateCredentials } = userCredentialsSlice.actions;
export default userCredentialsSlice.reducer;
