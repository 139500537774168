import React, { useEffect } from "react";
import Layout from "./components/Layout/Layout";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Provider } from "react-redux";
import store from "./store/store";

import "./App.css";

function App() {
  useEffect(() => {
    window.document.body.classList.add("init-app-loader-animate-hide");
    setTimeout(
      () => window.document.body.classList.add("init-app-loader-hidden"),
      1000
    );
  }, []);

  return (
    <Provider store={store}>
      <CssBaseline />
      <div className="App">
        <Layout />
      </div>
    </Provider>
  );
}

export default App;
