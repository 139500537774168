import React, { useCallback } from "react";
import TextInput from "../../../FormElements/TextInput/TextInput";
import LocationSearchInput from "../../../FormElements/LocationSearchInput/LocationSearchInput";
import ButtonGroupInput from "../../../FormElements/ButtonGroupInput/ButtonGroupInput";
import DateInput from "../../../FormElements/DateInput/DateInput";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import "./UserDetailsForm.scss";
import { routes } from "../../../../routes";

function UserDetailsForm(props) {
  const {
    values: {
      fullname,
      phoneNumber,
      socialNumber,
      email,
      birthdate,
      gender,
      city,
      street,
      streetNumber,
      accreditedFullname,
      accreditedSocialNumber,
      accreditedPhone,
    },
    errors,
    touched,
    handleSubmit,
    isValid,
    setFieldTouched,
    setFieldValue,
  } = props;

  const change = useCallback(
    (name, value) => {
      setFieldValue(name, value);
      setFieldTouched(name, true, false);
    },
    [setFieldValue, setFieldTouched]
  );

  return (
    <form
      className="userDetailsForm"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="fullnameAndPhone">
        <TextInput
          className="fullname"
          value={fullname}
          id={"fullname"}
          touched={touched}
          errors={errors}
          onChange={(event) => change("fullname", event.target.value)}
          label={"שם מלא"}
        />

        <TextInput
          className="phone"
          required
          value={phoneNumber}
          id={"phoneNumber"}
          touched={touched}
          errors={errors}
          onChange={(event) => change("phoneNumber", event.target.value)}
          label={"טלפון"}
        />
      </div>

      <div className="idAndEmail">
        <TextInput
          required
          className="id"
          value={socialNumber || ""}
          id={"socialNumber"}
          touched={touched}
          errors={errors}
          onChange={(event) => change("socialNumber", event.target.value)}
          label={"תעודת זהות"}
        />

        <TextInput
          className="email"
          required
          value={email}
          id={"email"}
          touched={touched}
          errors={errors}
          onChange={(event) => change("email", event.target.value)}
          label={"דואר אלקטרוני"}
        />
      </div>

      <div className="birthdateAndGender">
        <DateInput
          className="birthdate"
          required
          value={birthdate}
          id={"birthdate"}
          touched={touched}
          errors={errors}
          onChange={(value) => change("birthdate", value)}
          label={"תאריך לידה"}
        />

        <ButtonGroupInput
          required
          className="gender"
          value={gender}
          values={{
            זכר: "זכר",
            נקבה: "נקבה",
            אחר: "אחר",
          }}
          id={"gender"}
          touched={touched}
          errors={errors}
          onChange={(value) => change("gender", value)}
          label={"מין"}
        />
      </div>

      <div className="userDetailsSeparationLine"></div>

      <div className="address">
        <LocationSearchInput
          className="city"
          required
          value={city}
          id={"city"}
          touched={touched}
          errors={errors}
          onChange={(value) => change("city", value)}
          label={"יישוב"}
        />

        <TextInput
          className="streetAddress"
          // required
          value={street}
          id={"street"}
          touched={touched}
          errors={errors}
          onChange={(event) => change("street", event.target.value)}
          label={"רחוב"}
        />

        <TextInput
          className="streetNumber"
          // required
          value={streetNumber}
          id={"streetNumber"}
          touched={touched}
          errors={errors}
          onChange={(event) => change("streetNumber", event.target.value)}
          label={"מס' בית"}
        />
      </div>

      <div className="optional">
        <TextInput
          className="accreditedFullname"
          value={accreditedFullname || ""}
          id={"accreditedFullname"}
          touched={touched}
          errors={errors}
          onChange={(event) => change("accreditedFullname", event.target.value)}
          label={`שם מלא למיופה כח (אופצינלי)`}
        />

        <TextInput
          disabled={!accreditedFullname || !accreditedFullname.length}
          required={!!accreditedFullname}
          className="accreditedId"
          value={accreditedSocialNumber || ""}
          id={"accreditedSocialNumber"}
          touched={touched}
          errors={errors}
          onChange={(event) =>
            change("accreditedSocialNumber", event.target.value)
          }
          label={"ת.ז מיופה כח"}
        />
      </div>

      <div className="optionalFurther">
        <TextInput
          disabled={!accreditedFullname || !accreditedFullname.length}
          required={!!accreditedFullname}
          className="accreditedPhone"
          value={accreditedPhone || ""}
          id={"accreditedPhone"}
          touched={touched}
          errors={errors}
          onChange={(event) => change("accreditedPhone", event.target.value)}
          label={"טלפון מיופה כח"}
        />
      </div>

      <div className="submitOrBack">
        <Link className="back" to={routes.homePage}>
          חזרה
        </Link>
        <Button
          className="submitButton"
          type="submit"
          variant="contained"
          disabled={!isValid}
        >
          המשך
        </Button>
      </div>
    </form>
  );
}

export default UserDetailsForm;
