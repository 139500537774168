import { createSlice } from "@reduxjs/toolkit";


/**
 * "createSlice" wraps reducer function with produce from the Immer library.
 * This means you can write code that "mutates" the state inside the reducer,
 * and Immer will safely return a correct immutably updated result.
 */
const loaderSlice = createSlice({
  name: "loader",
  initialState: { isLoading: false },
  reducers: {
    showLoader(state, action) {
      state.isLoading = true;
    },
    hideLoader(state, action) {
      state.isLoading = false;
    },
  },
});

export const { showLoader, hideLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
