import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { LoginPageForm } from "./LoginPageForm/LoginPageForm";
import { LoginPageSchema } from "./loginPageSchema";
import { LOGIN_PAGE } from "../../../constants";
import { Modal } from "../../Modal/Modal";
import LoginOrRegisterFailure from "../../Notifications/LoginOrRegisterFailure.js/LoginOrRegisterFailure";
import { loginPageTexts } from "../../../texts";
import { useHistory } from "react-router-dom";
import { routes } from "../../../routes";
import { loginRequest, resetError } from "../../../store/slices/loginSlice";
import { setCurrentPage } from "../../../store/slices/currentPageSlice";

import "./LoginPage.scss";

function LoginPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoginFinished = useSelector(
    (state) => state.loginReducer.isLoginFinished
  );
  const error = useSelector((state) => state.loginReducer.error);

  useEffect(() => {
    dispatch(setCurrentPage(LOGIN_PAGE));
    dispatch(resetError());
  }, []);

  useEffect(() => {
    if (isLoginFinished && !error) {
      history.push(routes.loginVerificationPage);
    }
  }, [isLoginFinished, error, history]);

  const submit = useCallback((values) => {
    dispatch(loginRequest({...values, 'isExistingCustomer': true}));
  }, []);

  const formikInitialValues = { userId: "", userPhone: "" };

  return (
    <div className="loginPage">
      <Modal
        notificationClass={"notificationClass"}
        show={isLoginFinished && error}
        handleClose={() => dispatch(resetError())}
      >
        <LoginOrRegisterFailure onFinish={() => dispatch(resetError())} />
      </Modal>
      <div className="loginGreetingText">
        {loginPageTexts.loginPageGreetingText}
      </div>
      <div className="loginConfirmDetailsText">
        {loginPageTexts.loginPageConfirmDetailsText}
      </div>
      <Formik
        onSubmit={submit}
        children={(props) => <LoginPageForm {...props} />}
        initialValues={formikInitialValues}
        validationSchema={LoginPageSchema}
      />
    </div>
  );
}
export default LoginPage;
