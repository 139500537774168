import * as Yup from "yup";

const licenseRegex = /^[0-9]+$/g;
const textInputRegex = /^\D+$/;

export const AdditionalDetailsSchema = Yup.object().shape({
  licenseNumber: Yup.string()
    .required("שדה זה חובה למילוי")
    .matches(licenseRegex, "קוד שגוי - נסה שנית"),
  caretakerFullname: Yup.string()
    .required("שדה זה חובה למילוי")
    .matches(textInputRegex, "נא להקליד שם תקין")
    .max(48, "נא להקליד שם עד 48 תווים").nullable(),
  licenceExpirationDate: Yup.string().required(),
  allowedAmount: Yup.string().nullable(),
  medicalField: Yup.string().nullable(),
  useCase: Yup.string().nullable(),
  other: Yup.string().max(40, "השדה מוגבל ל 40 תווים").nullable(),
});
