import React, { useCallback } from "react";
import TextInput from "../../../FormElements/TextInput/TextInput";
import DateInput from "../../../FormElements/DateInput/DateInput";
import ExpirationDate from "../../../FormElements/DateInput/ExpirationDate";
import SelectInput from "../../../FormElements/SelectInput/SelectInput";
import TextareaInput from "../../../FormElements/TextareaInput/TextareaInput";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import "./AdditionalDetailsForm.scss";
import { routes } from "../../../../routes";

function AdditionalDetailsForm(props) {
  const {
    values: {
      licenseNumber,
      caretakerFullname,
      licenceExpirationDate,
      allowedAmount,
      medicalField,
      useCase,
      otherReasonsForUsecase,
    },
    errors,
    touched,
    handleSubmit,
    isValid,
    setFieldTouched,
    setFieldValue,
  } = props;

  const change = useCallback(
    (name, value) => {
      setFieldValue(name, value);
      setFieldTouched(name, true, false);
    },
    [setFieldValue, setFieldTouched]
  );

  return (
    <form
      className="additionalDetailsForm"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="licenseNumberinput">
        <TextInput
          className="licenceNumber"
          required
          value={licenseNumber || ""} 
          id={"licenseNumber"}
          touched={touched}
          errors={errors}
          onChange={(event) => change("licenseNumber", event.target.value)}
          label={"מספר רישיון קנאביס"}
        />
      </div>
      <div className="licenseDetails">
        <TextInput
          required
          className="careGiverFullname"
          value={caretakerFullname || ""}
          id={"caretakerFullname"}
          touched={touched}
          errors={errors}
          onChange={(event) => change("caretakerFullname", event.target.value)}
          label={"שם הרופא המטפל"}
        />

        <ExpirationDate
          className="licenseExpirationDate"
          required
          value={licenceExpirationDate}
          id={"licenseExpirationDate"}
          touched={touched}
          errors={errors}
          onChange={(value) => change("licenceExpirationDate", value)}
          label={"תוקף הרישיון"}
        />
        
        <SelectInput
          selectValues={allowedWeights}
          className="allowedWeight"
          value={allowedAmount || ""}
          id={"allowedAmount"}
          touched={touched}
          errors={errors}
          onChange={(value) => change("allowedAmount", value)}
          label={"כמות מותרת (ג')"}
        />
      </div>
      <div className="additionalDetailsSeparationLine"></div>

      <div className="medicalInfo">
        <SelectInput
          selectValues={medicalFields}
          className="medicalField"
          value={medicalField || ""}
          id={"medicalField"}
          touched={touched}
          errors={errors}
          onChange={(newValue) => change("medicalField", newValue)}
          label={"תחום רפואי"}
        />

        <SelectInput
          hasTooltip={"hasTooltip"}
          disabled={!medicalField || !medicalField.length}
          selectValues={medicalField ? usages[medicalField] : []}
          className="usage"
          value={useCase}
          id={"useCase"}
          touched={touched}
          errors={errors}
          onChange={(newValue) => change("useCase", newValue)}
          label={"סיבות לשימוש"}
        />
      </div>

      <div className="otherTextArea">
        <TextareaInput
          rows="10"
          disabled={
            !medicalField || !medicalField.length || (medicalField && !!useCase)
          }
          className="other"
          value={otherReasonsForUsecase || ""}
          id={"otherReasonsForUsecase"}
          touched={touched}
          errors={errors}
          onChange={(event) =>
            change("otherReasonsForUsecase", event.target.value)
          }
          label={"סיבה אחרת"}
        />
      </div>

      <div className="submitOrBack">
        <Link className="back" to={routes.uploadDocumentPage}>
          חזרה
        </Link>
        <Button
          className="submitButton"
          type="submit"
          variant="contained"
          disabled={!isValid}
        >
          המשך
        </Button>
      </div>
    </form>
  );
}
export default AdditionalDetailsForm;

const medicalFields = [
  { value: "oncology", title: "תחום האונקולוגיה" },
  { value: "gastro-oncology", title: "תחום הגסטרואנטרולוגיה" },
  { value: "pain", title: "תחום הכאב" },
  { value: "infectious-diseases", title: "תחום המחלות הזיהומיות" },
  { value: "neorology", title: "תחום הנוירולוגיה" },
  { value: "palliative", title: "תחום טיפול פליאטיבי" },
  { value: "psychiatry", title: "תחום הפסיכיאטריה" },
];

const allowedWeights = [
  { value: 10, title: "10" },
  { value: 20, title: "20" },
  { value: 30, title: "30" },
  { value: 40, title: "40" },
  { value: 50, title: "50" },
  { value: 60, title: "60" },
  { value: 70, title: "70" },
  { value: 80, title: "80" },
  { value: 90, title: "90" },
  { value: 100, title: "100" },
  { value: 110, title: "110" },
  { value: 120, title: "120" },
  { value: 130, title: "130" },
  { value: 140, title: "140" },
  { value: 150, title: "150" },
  { value: 160, title: "160" },
  { value: 170, title: "170" },
  { value: 180, title: "180" },
  { value: 190, title: "190" },
  { value: 200, title: "200" },
  { value: 210, title: "210" },
  { value: 220, title: "220" },
];

export const usages = {
  oncology: [
    { value: "הקטנת גידול", title: "הקטנת גידול" },
    {
      value: "תופעות לוואי של טיפול אנטיאופלסטי",
      title: "תופעות לוואי של טיפול אנטיאופלסטי",
    },
    { value: "תופעות נלוות לסרטן", title: "תופעות נלוות לסרטן" },
  ],
  "gastro-oncology": [
    { value: "קרוהן", title: "קרוהן" },
    { value: "קוליטיס כיבית", title: "קוליטיס כיבית" },
  ],
  pain: [
    { value: "אנדומטריוזיס", title: "אנדומטריוזיס" },
    { value: "דלקת פרקים ראומטית", title: "דלקת פרקים ראומטית" },
    { value: "מיגרנה", title: "מיגרנה" },
    { value: "סקלרודרמה", title: "סקלרודרמה" },
    { value: "פיברומיאלגיה", title: "פיברומיאלגיה" },
    { value: "פריצת דיסק", title: "פריצת דיסק" },
    { value: "כאב אחר", title: "כאב אחר" },
  ],
  "infectious-diseases": [{ value: "איידס/ +HIV", title: "איידס/ +HIV" }],
  neorology: [
    { value: "טרשת נפוצה", title: "טרשת נפוצה" },
    { value: "פרקינסון", title: "פרקינסון" },
    { value: "טוראט", title: "טוראט" },
    { value: "אפילפסיה", title: "אפילפסיה" },
    { value: "אוטיזם", title: "אוטיזם" },
    { value: "הפרעת קשב וריכוז", title: "הפרעת קשב וריכוז" },
  ],
  psychiatry: [
    { value: "הפרעת דחק פוסט טראומטית", title: "הפרעת דחק פוסט טראומטית" },
  ],
};
