import { WELCOME_PAGE, USER_DETAILS_PAGE, UPLOAD_DOCUMENT_PAGE, ADDITIONAL_DETAILS_PAGE, FINISH_PROCESS_PAGE } from "../../constants";
import { REGISTER_PAGE } from "../../constants";
import { VERIFICATION_PAGE } from "../../constants";



export const progressMapping = {
  [WELCOME_PAGE]: 0,
  [REGISTER_PAGE]: 0,
  [VERIFICATION_PAGE]: 0,
  [USER_DETAILS_PAGE]: 1,
  [UPLOAD_DOCUMENT_PAGE]: 2,
  [ADDITIONAL_DETAILS_PAGE]: 3,
  [FINISH_PROCESS_PAGE]: 4
}