import React, { useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";
import tooltipIcon from "../../../assets/images/tooltipIcon.svg";
import "./SelectInput.scss";
import { selectInputTexts } from "../../../texts";

function SelectInputForm(props) {
  const {
    value,
    id,
    touched,
    errors,
    onChange,
    label,
    required,
    className,
    disabled,
  } = props;
  const selectedValues = props.selectValues;

  const tooltipClasses = makeStyles((theme) => ({
    arrow: {
      color: "white",
    },
    tooltip: {
      font:
        "font: var(--font-style-normal) normal var(--font-weight-normal) 12px/22px var(--font-family-primary);",
      backgroundColor: "white",
      color: "black",
      boxShadow: "0px 3px 10px #00000029",
    },
  }))();

  const selectClasses = makeStyles((theme) => ({
    select: {
      padding: "3px 5px",
    },
    icon: {
      top: "calc(50% - 0.5em)!important",
    },
  }))();

  const menuClasses = makeStyles((theme) => ({
    list: {
      padding: "0!important",
    },
  }))();

  const selectContainerRef = useRef(null);

  return (
    <FormControl
      className={`selectInput ${
        touched[id] && !!errors[id] ? "hasError" : ""
      } ${className} ${disabled ? "disabled" : ""} `}
    >
      <label htmlFor={id}>
        {label}
        {required && <span className={"required"}>*</span>}
        {props.hasTooltip && (
          <Tooltip
            classes={tooltipClasses}
            arrow
            title={selectInputTexts.tooltipText}
            placement="top"
            enterTouchDelay={500}
          >
            <span className="errorIcon">
              <img alt="" src={tooltipIcon} />
            </span>
          </Tooltip>
        )}
      </label>
      <div ref={selectContainerRef} className="selectConatainer">
        <Select
          classes={selectClasses}
          variant={"outlined"}
          inputProps={{
            placeholder: `${selectInputTexts.placeholderText}`,
          }}
          MenuProps={{
            // anchorEl: selectContainerRef.current,
            variant: "menu",
            getContentAnchorEl: null,
            elevation: 1,
            classes: menuClasses,
          }}
          className="select"
          id={id}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          disabled={disabled}
        >
          <MenuItem value="">{selectInputTexts.placeholderText}</MenuItem>
          {selectedValues && selectedValues.map((field, index) => (
            <MenuItem
              className="selectOptionItem"
              key={index}
              value={field.value}
            >
              {field.title}
            </MenuItem>
          ))}
        </Select>
      </div>
      {touched[id] && !!errors[id] && (
        <div className="errorMessage">
          <Tooltip
            classes={tooltipClasses}
            arrow
            title={errors[id]}
            placement="top"
          >
            <span className="errorIcon">i</span>
          </Tooltip>
        </div>
      )}
    </FormControl>
  );
}

export default SelectInputForm;
