import React from "react";
import phoneIcon from "../../assets/images/PhoneIcon.svg";
import locationLogo from "../../assets/images/LocationIcon.svg";
import questionMarkIcon from "../../assets/images/QuestionMarkIcon.svg";
import atSignIcon from "../../assets/images/AtSign.svg";
import { footerTexts } from "../../texts";
import { showFaq, hideFaq } from "../../store/slices/faqSlice";

import "./Footer.scss";
import { useDispatch } from "react-redux";

function Footer() {
  const dispatch = useDispatch();

  return (
    <div className="footer">
      <div className="footerItem">
        <img alt="alt" src={phoneIcon} />
        <span className="footerText">{footerTexts.customerServiceText}</span>
        <span className="footerValue">{footerTexts.customerServiceValue}</span>
      </div>
      <div className="footerItem">
        <img alt="alt" src={locationLogo} />
        <span className="footerText">{footerTexts.serviceAddressText}</span>
        <span className="footerValue">{footerTexts.serviceAddressValue}</span>
      </div>
      <div className="footerItem">
        <span className="footerText">{footerTexts.serviceEmailText}</span>
        <span className="footerValue">{footerTexts.serviceEmailValue}</span>
        <img className="atSignIcon" alt="alt" src={atSignIcon} />
      </div>
      <div className="qustionsAndAnswers">
        <button
          className="faq"
          onClick={() => dispatch(showFaq())}
          type="button"
        >
          <img alt="alt" src={questionMarkIcon} />
          שאלות ותשובות
        </button>
      </div>
    </div>
  );
}

export default Footer;
