import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ADDITIONAL_DETAILS_PAGE } from "../../../constants";
import AdditionalDetailsForm from "./AdditionalDetailsForm/AdditionalDetailsForm";
import { AdditionalDetailsSchema } from "./additionalDetailsSchema";
import { Formik } from "formik";
import { additionalDetailsPageTexts } from "../../../texts";
import { updateUserAdditionalDetailsRequest } from "../../../store/slices/userAdditionalDetailsSlice";
import { routes } from "../../../routes";
import { setCurrentPage } from "../../../store/slices/currentPageSlice";

import "./AdditionalDetailsPage.scss";

function AdditionalDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userReducer);
  const isUserAdditionalDetailsFinished = useSelector(
    (state) =>
      state.userAdditionalDetailsReducer.isUserAdditionalDetailsFinished
  );
  const error = useSelector(
    (state) => state.userAdditionalDetailsReducer.error
  );

  const submit = (values) => {
    dispatch(updateUserAdditionalDetailsRequest(values));
  };

  useEffect(() => {
    if (isUserAdditionalDetailsFinished && !error) {
      history.push(routes.finishProcessPage);
    }
  }, [isUserAdditionalDetailsFinished, error, history]);

  useEffect(() => {
    dispatch(setCurrentPage(ADDITIONAL_DETAILS_PAGE));
  }, []);

  return (
    <div className="additionalDetails">
      <div className="addtionalDetailsText">
        {additionalDetailsPageTexts.additionalDetailsPageMainText}
      </div>
      <Formik
        onSubmit={submit}
        initialValues={userDetails || {}}
        validationSchema={AdditionalDetailsSchema}
        enableReinitialize
        children={(props) => <AdditionalDetailsForm {...props} />}
      />
    </div>
  );
}
export default AdditionalDetails;
