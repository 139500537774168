import React from "react";
import "./Modal.scss";

export const Modal = ({
  handleClose,
  show,
  children,
  notificationClass,
  className,
}) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const modalStyles = notificationClass ? "modal-notify" : "modal-main";

  return (
    <div className={showHideClassName}>
      <section className={`${modalStyles} ${className || ""}`}>
        {children}
      </section>
    </div>
  );
};
