import React from "react";
import "./TermsOfUseText.scss";

function TermsOfUseText(props) {
  return (
    <div className="termsOfUseText">
      <p>
        אנו שמחים שבחרת להשתמש במוצר מבית פנאקסיה לוגיסטיקה בע"מ, ח.פ 515752053,
        עבור לומינרה פארם בע"מ, ח.פ 515681948 ("אנו"), אנו משמשים כחנות ובית
        מרקחת מקוונים לרכישת מוצרים מבוססי קנאביס כמפורט בתנאי שימוש ורכישה אלו,
        בהתאם ובכפוף להוראות של משרד הבריאות. הפלטפורמה כוללת מספר ממשקים
        ומוצרים, לרבות אתר אינטרנט, אפליקציה סלולארית, מוקד הזמנה טלפוני, וכן
        היא כוללת כל ערוץ אחר בו תבחר ומתאפשר ליצור איתנו קשר, למשל פניות
        טלפונית והודעות דוא"ל (ביחד, "הפלטפורמה"). אנא הרגש חופשי{" "}
        <span className="useTerms">לפנות אלינו</span> בכל שאלה לגבי השימוש
        בפלטפורמה או תנאי שימוש אלו.
      </p>
      {/* <p> */}
      <ol>
        <li>
          <strong>
            <u>כללי</u>
          </strong>
        </li>
        <p>
          כללי תנאי שימוש ורכישה אלו נוסחו על ידינו כדי לסייע לך להבין מה התנאים
          שיחולו על השימוש שלך בפלטפורמה ועל כל הזמנה או רכישה שתבצע באמצעות
          הפלטפורמה. השימוש בפלטפורמה מבטא הסכמה מצדך לכל תנאי השימוש המפורטים
          במסמך זה וב
          <span className="useTerms">מדיניות הפרטיות</span> שלנו (ביחד, "תנאי
          השימוש"). בעצם השימוש, אתה מצהיר ומאשר כי קראת את תנאי השימוש וכי אתה
          מודע ומסכים להם. תנאי השימוש חלים על השימוש בפלטפורמה באמצעות כל מכשיר
          קצה. אם אתה לא מסכים לתנאי השימוש, כולם או כל חלק מהם, או אין ברצונך
          למסור את המידע (כולו או חלקו) כמפורט בתנאי השימוש, אתה מתבקש שלא לעשות
          שימוש בפלטפורמה. אנו שומרים לעצמנו את הזכות שלא לאפשר כניסה לפלטפורמה
          למי שיפר את תנאי השימוש או יעשה שימוש בלתי ראוי בפלטפורמה. לצורך תנאי
          השימוש "המשתמש" או ״אתה״ הוא יחיד אשר בחר להיכנס לפלטפורמה ולעשות
          שימוש אישי בה, לרבות על ידי רכישת מוצרים. הפלטפורמה מיועדת לשימוש של
          יחידים בעלי הכשרות המשפטית להתחייב לתנאי שימוש אלה וליצור חוזה מחייב
          בהתאם לדין החל. הפלטפורמה מיועדת לשימוש לתושבי מדינת ישראל בלבד.{" "}
          <strong>
            הפלטפורמה זמינה באמצעים שונים. אנא שים לב כי ייתכנו שינויים בשימוש
            בין האמצעים השונים, למשל, באמצעות אתר מותאם מכשיר לאפליקציה. שים לב
            – הפלטפורמה איננה מיועדת
          </strong>{" "}
          לשימוש במצבי חירום. אם מדובר במצב חירום רפואי, אנא צור קשר עם הגורם
          הרפואי מתאים. אנו שומרים לעצמנו את הזכות לעדכן את תנאי השימוש או לבצע
          שינוי בפלטפורמה או בשירותים שאנו נותנים בהתאם לשיקול דעתנו, ללא צורך
          בהודעה מוקדמת. כל שינוי מהותי בתנאי השימוש יפורסם{" "}
          <span className="useTerms">באתר שלנו</span>. מועד החלת השינוי יהיה
          מועד פרסום תנאי השימוש המעודכנים.
        </p>
        <li>
          <strong>
            <u>הרשמה ונתוני משתמש</u>
          </strong>
        </li>
        <p>
          כדי להשתמש בפלטפורמה ולבצע הזמנה עליך להירשם. תהליך ההזדהות כולל מתן
          הפרטים הבאים: שם מלא, מספר תעודת זהות, תאריך לידה, פרטי התקשרות (טלפון
          וכתובת דואר אלקטרוני), מרשם רפואי (כהגדרתו מטה) וכן כל מידע נוסף שנדרש
          לצורך ביצוע ההזמנה ומתן השירותים. אתה מתחייב כי המידע שתמסור יהיה
          מדויק, מלא ומעודכן. איננו אחראיים לבדיקת הפרטים שיירשמו על ידך. בעת
          ההרשמה לפלטפורמה תתבקש להעלות את המרשם הרפואי שלך למוצר. כמו-כן, בכל
          מועד עדכון או שינוי במרשם הרפואי, תתבקש להעלות את המרשם הרפואי
          המעודכן. המוצרים יסופקו אך ורק על פי מרשם רפואי מקורי או על-פי מרשם
          מקוון בהתאם להוראות חוק חתימה אלקטרונית התשס"א-2001 ותקנותיו ונהלי
          משרד הבריאות (להלן: "<strong>המרשם הרפואי</strong>"). את המרשם הרפואי
          יש להעלות לפלטפורמה וכן להציגו לשליח במועד השליחות (כמתואר בסעיף 7
          להלן). במקרה שהניפוק מבוצע על סמך מרשם רפואי מקוון יש צורך בחתימתך על
          גבי המרשם. נהיה רשאים שלא לכבד את המרשם הרפואי אם לדעתנו כיבוד המרשם
          הרפואי עלול לפגוע בבריאותך בכל דרך שהיא. <br /> לאחר הרשמה ראשונה,
          תוכל לשוב ולהשתמש בפלטפורמה באמצעות שם משתמש וסיסמה ללא צורך ברישום
          מחדש. <br />
          אינך מחויב על פי דין למסור את המידע, אך אם תבחר שלא למסור אותו, לא
          נוכל לספק לך את השירותים באמצעות הפלטפורמה. כל שימוש במידע ושמירתו
          ייעשו בהתאם למפורט בתנאי השימוש וב
          <span className="useTerms">מדיניות הפרטיות</span>. אפוטרופוס של קטין
          או חסוי, המורשה כדין לייצג אותם, ומחזיק ייפוי כוח לרכישה עבורם לפי
          משרד הבריאות, רשאי לעשות שימוש בפלטפורמה על מנת לבצע את הרכישה עבור
          משתמשים אלו (להלן: "משתמשים מאושרים"). במצב זה, אנא מלא את הפרטים של
          המשתמש המאושר בפרטי המשתמש וכן אנא העלה את ייפוי הכוח לרכישה אשר קיבלת
          עבור המשתמש המאושר. על מנת להגן על פרטיך האישים, אנו ממליצים לך לא
          לגלות או להעביר לאחר את שם המשתמש והסיסמה שלך. במקרה של אובדן או גניבה
          של שם המשתמש או הסיסמה שלך, אנא פעל לשינויים. אם אינך בטוח כיצד לעשות
          זאת, אנא <span className="useTerms">צור קשר</span> ונשמח לסייע. אם
          תרצה לרכוש מוצר באמצעות הטלפון, מוקד שירות הלקוחות שלנו ישמח לסייע לך.
          אנא <span className="useTerms">צור קשר</span>.
        </p>
        <li>
          <strong>
            <u>הזמנה, מוצרים ורכישתם </u>
          </strong>
        </li>
        <p>
          בתנאי שימוש אלו, "<strong>המוצרים</strong>" פירושם מוצרים מבוססי
          קנאביס רפואי הנמכרים על ידנו באמצעות הפלטפורמה. <br />
          הפלטפורמה אינה מהווה הצעה למכירת מוצרים. קבלת הזמנה מאת משתמש לרכישת
          מוצר תהווה הצעה של המשתמש לרכישתו וקבלת ההצעה תתבצע באמצעות אספקה
          בפועל של המוצר, בכפוף למלאי הקיים ולהוראות תנאי השימוש. <br />
          אופן הצגת המוצרים בפלטפורמה ייקבע על ידנו מעת לעת, והכל בהתאם לחוק.{" "}
          <br />
          הפלטפורמה כוללת "<strong>דף מוצר</strong>" עבור כל מוצר, המכיל פרטים
          על המוצר, לרבות שם, תיאור ומפרט המוצר (משקל, כמות CBD, ו-THC), הפנייה
          לעלון מידע וכל נתון אחר הנדרש להצגה לפי דין. תמונות המוצרים ושמות
          המוצרים נועדו לנוחיותך ולהמחשה בלבד והם אינם מחייבים. ייתכנו טעויות
          ו/או אי התאמות בשמות ותמונות המוצרים ואתה מתבקש לבדוק בקפידה את המידע
          המופיע בדף המוצר ובעלון המידע בטרם ביצוע הזמנה. המידע המחייב הוא המידע
          המופיע בעלון המידע. במידה וחלה טעות בתיאור המוצר או בכל נתון אחר בדף
          המוצר, נהיה רשאים לבטל את ההזמנה בטרם אספקת המוצר. <br />
          המשתמש יכול לבחור מוצרים בפלטפורמה ולהוסיף אותם לסל הקניות שלו מתוך
          מגוון המוצרים המוצגים בפלטפורמה. אתה זכאי להזמין מוצרים, לרבות סוג
          וכמות, אך ורק בהתאם למרשם הרפואי שהונפק לך. <br />
          לאחר מילוי הסל, סיום תהליך ההזמנה יתבצע באמצעות מעבר לקופה והשלמת פרטי
          התשלום. במסגרת הזמנה של מוצרים אשר ניפוקם מחייב תשאול רוקחי, יבוצע
          תשאול רוקחי ורק לאחר השלמת התשאול כקבוע בחוק תתאפשר השלמת ההזמנה.{" "}
          <br />
          מגוון המוצרים בפלטפורמה תלוי בזמינותם. אנו רשאים להחליף או לשנות את
          המוצרים המופיעים בפלטפורמה. ככל שהזמנת מוצרים אשר במועד הכנת ההזמנה לא
          יהיו במלאי, אנו ניצור עמך קשר כדי להציע לך מוצרים חלופיים. אתה תהיה
          רשאי להסכים או שלא להסכים לקבלת מוצר חלופי ובכל מקרה לא ייגבה ממך
          תשלום בעבור מוצר חלופי ללא אישורך להחלפה. בסיום תהליך ההזמנה תקבל
          הודעת דואר אלקטרוני אישית הכוללת את פרטי ההזמנה ומספר הזמנה ייחודי
          שיהווה אסמכתא לקבלת בקשת ההזמנה. לתשומת ליבך כי פעולת הרכישה תאושר רק
          לאחר בדיקה שלנו את ההזמנה שבוצעה למול המרשם הרפואי שלך ווידוא כי
          ההזמנה תואמת את המרשם הרפואי, לרבות באמצעות בדיקת תקינות המרשם הרפואי
          מול משרד הבריאות. לא יתבצע חיוב טרם השלמת הבדיקה. ככל שהתבצע חיוב
          בשוגג בטרם השלמת הבדיקה ולאחר השלמת הבדיקה יתברר כי ההזמנה אינה תואמת
          את המרשם הרפואי, ההזמנה תבוטל ויתבצע זיכוי מלא של התמורה שהתקבלה.{" "}
          <br />
          לא ייגבה ממך תשלום עבור מוצרים שלא סופקו. <br />
          אם העסקה לא אושרה על ידי אמצעי התשלום כהגדרתו מטה, תישלח אליך הודעה
          מתאימה וההזמנה תיחשב כבטלה. <br />
          לאחר קבלת אישור מאמצעי התשלום אודות אישור חברת האשראי על ההזמנה שלך,
          אנו נשלח לך אישור לדואר האלקטרוני בדבר אישור הרכישה.
        </p>
        <li>
          <strong>
            <u>מחירים, מבצעים ודמי משלוח</u>
          </strong>
        </li>
        <p>
          מחירי המוצרים יפורסמו בדף המוצר. אנו נהיה רשאים לעדכן את המחירים מעת
          לעת ובלבד שעדכון זה לא ישפיע על הזמנה שבוצעה. מחירי המוצרים כוללים מס
          ערך מוסף (מע"מ). בשלב זה אנו לא גובים תשלום בעבור משלוח. אנו נהיה
          רשאים, מפעם לפעם, להעניק הטבות או להציע מבצעים על מוצרים מסוימים. כל
          המבצעים וההטבות יהיו תקפים לגבי רכישות שבוצעו בזמן המבצע או ההטבה, לפי
          העניין, ויהיו כפופים לתנאי רכישה ספציפיים אשר יפורסמו בקשר עם המבצע או
          ההטבה, לפי העניין. במקרה בו הזמנת מוצר במבצע אשר אזל במלאי, המבצע לא
          יישמר ותהיה רשאי להזמין מוצר חלופי לפי מחיר אותו מוצר חלופי. שים לב כי
          במקרה של שינוי סל המוצרים בהזמנה יעודכנו המחירים, ההטבות והמבצעים
          בהתאם ליום שליחת ההזמנה. אנו נהיה רשאים להגביל את מספר וכמות המוצרים
          אשר ניתן לרכוש.
        </p>
        <li>
          <strong>
            <u>אמצעי התשלום</u>
          </strong>
        </li>
        <p>
          התשלום בגין רכישה בפלטפורמה יתבצע באמצעות תשלום בכרטיס אשראי, באמצעות
          מערכת{" "}
          <a
            href="https://www.icount.co.il/privacy-policy/"
            className="useTerms"
          >
            Icount
          </a>{" "}
          ("אמצעי התשלום"). תנאי ההזמנה והשימוש באמצעי התשלום וכן תנאי התשלום
          והאשראי יהיו בהתאם לתקנון אמצעי התשלום, ואנו לא נהווה צד להסכם עם
          אמצעי התשלום. חשבונית עסקה או חשבונית מס ממוחשבת תישלח באמצעות דואר
          אלקטרוני לאחר אספקת המוצרים, ואתה מאשר כי אתה מסכים למשלוח החשבונית
          באמצעות הדואר האלקטרוני. תוכל{" "}
          <span className="useTerms">לפנות אלינו</span> בכל עת כדי לבטל את
          הסכמתך לקבלת החשבונית באמצעות דואר אלקטרוני.
        </p>
        <li>
          <strong>
            <u>אספקה ושליחות </u>
          </strong>
        </li>
        <p>
          <strong>
            {" "}
            אנו מספקים שירות משלוחים עד הבית, בהתאם לכתובת המופיעה במרשם הרפואי.
            בשלב זה שירות המשלוחים ניתן ללא תשלום. אנו נהיה רשאים לשנות את תנאי
            המשלוח מעת לעת בעבור הזמנות עתידיות.
          </strong>{" "}
          אנו נהיה רשאים לסרב לספק משלוח כאשר נדרשות פעולות מיוחדות לצורך אספקת
          המשלוח, האזור מוגבל לגישה, או מכל סיבה רלוונטית אחרת, לפי שיקול דעתנו.{" "}
          <strong>
            בעת הזמנת המוצרים, תתבקש לבחור תאריך אספקה. יום קודם לתאריך האספקה
            שנבחר על ידך, תישלח אליך הודעת טקסט שתאפשר לך לבחור ולעדכן את זמן
            הגעת המשלוח.
          </strong>{" "}
          חלון המשלוח יהיה לטווח הכולל פרק זמן של כ-3 שעות{" "}
          <strong>
            והמשלוח יתבצע בתאריך האספקה בהתאם לחלון המשלוח שתבחר. אנו לא נהיה
            אחראיים לעיכובים שאינם בשליטתנו או שמקורם במשתמש, לרבות עיכוב בגין
            קושי ליצור איתך קשר. עיכוב כאמור לא ייחשב כהפרת התחייבויותינו.
          </strong>{" "}
          אספקת המוצרים תתבצע בהתאם להוראות הדין, לרבות נוהל 128 של משרד
          הבריאות, ובכפוף להוראותיו כפי שתהיינה בתוקף בעת ביצוע האספקה.{" "}
          <strong>
            בהתאם, המשלוח יימסר לך רק לאחר הזדהות והצגת תעודה מזהה, מסירת המרשם
            הרפואי ובכפוף לחתימה האלקטרונית (בתום ההזמנה) על ייפוי כוח לאספקת
            התכשירים באמצעות שליח בנוסח המאושר על ידי משרד הבריאות באמצעות אישור
            אלקטרוני בפלטפורמה.
          </strong>{" "}
          <strong>
            אתה מתבקש לפתוח את ההזמנה מול השליח ולוודא את התאמת המוצרים להזמנה
            ואת תקינותם. לאחר בדיקתך תתבקש לחתום על "טופס אישור קבלת מוצר קנביס"
            בנוסח המאושר על ידי משרד הבריאות{" "}
          </strong>
          ("אישור קבלה").{" "}
          <strong>
            ככל שקיימת אי התאמה בין פרטי ההזמנה לבין המוצרים שסופקו, או במידה
            והמוצרים אינם תקינים או פגומים, אתה מתבקש להודיע על כך לשליח באופן
            מידי ולא לחתום על אישור הקבלה.
          </strong>
        </p>
        <li>
          <strong>
            <u>שינויים בהזמנות, ביטול עסקה והחזרת מוצרים</u>
          </strong>
        </li>
        <p>
          אתה תהיה רשאי לבצע שינויים בהזמנה או לבטל אותה עד לשעה שתיים בצהריים
          יום לפני מועד האספקה{" "}
          <strong>המתוכנן, באמצעות הפלטפורמה או על ידי</strong>{" "}
          <span className="useTerms">יצירת קשר</span>. במקרה של שינוי בהזמנה,
          יעודכנו המחירים, המבצעים וההטבות בהזמנה בהתאם ליום סגירת ושליחת ההזמנה
          המעודכנת.{" "}
          <strong>
            לאחר מועד זה, לא ניתן יהיה לעשות שינויים או לבטל את ההזמנה. במידה
            ויתברר לנו כי אין באפשרותנו, מכל סיבה שהיא, לספק את המוצרים במועד
            האספקה המתוכנן, לרבות בשל כך שהמוצר אזל מהמלאי, אנו נהיה רשאים
            להודיעך אודות אי אספקת המוצרים ולבטל את העסקה.
          </strong>{" "}
          אנו לא נהיה אחראיים ולא נישא בכל נזק ישיר, עקיף, תוצאתי או מיוחד שנגרם
          לך או לצד שלישי בגין ביטול הזמנה כאמור.{" "}
          <strong>
            אתה מתבקש לבדוק בקפידה את הזמנתך בטרם תחתום על אישור הקבלה. בהתאם
            לקבוע בחוק הגנת הצרכן התשמ"א-1981 וחוק המכר התשכ"ח-1968, לא ניתן
            יהיה להחזיר מוצרים שסופקו ואשר חתמת על אישור קבלה בגינם מכל סיבה
            שהיא. במקרה בו, על אף שנעשתה בדיקה על ידך, סופק לך בטעות מוצר פגום,
            אנא
          </strong>{" "}
          <span className="useTerms">פנה אלינו</span>{" "}
          <strong>
            באופן מידי כדי שנדאג לאסוף את המוצר התקול ולספק לך מוצר חלופי. אנו
            נשתדל לענות לכל פנייה במהירות ובהקדם האפשרי, ובכל מקרה תקבל מענה לכל
            המאוחר עד 21 יום ממועד פנייתך.
          </strong>
        </p>
        <li>
          <strong>
            <u>פרטיות ופרסום מותר</u>
          </strong>
        </li>
        <p>
          כל הפרטים שתמסור בעת רישומך לפלטפורמה ושימושך בפלטפורמה נמסרים בהתאם
          לרצונך האישי ובהסכמתך והם יישמרו במאגר המידע שלנו (למעט פרטי כרטיס
          אשראי אשר יישמרו על ידי אמצעי התשלום כמפורט לעיל). יובהר כי אינך חייב
          על פי חוק למסור את המידע, אך ללא מסירתו לא נוכל להעניק לך שירותים
          באמצעות הפלטפורמה. איסוף ושמירת המידע ייעשו בהתאם ובכפוף לקבוע{" "}
          <span className="useTerms">במדיניות הפרטיות</span>. בעת מסירת המידע
          תוכל לבחור להסכים לקבל מידע פרסומי מאיתנו, לרבות עדכונים אודות מוצרים.
          אנו סבורים כי תמצא ערך בקבלת מידע פרסומי זה, אולם תוכל לבחור לחזור בך
          מהסכמתך בכל עת באמצעות <span className="useTerms">הודעה</span> על
          סירובך לקבל מידע פרסומי.
        </p>
        <li>
          <strong>
            <u>השימוש בפלטפורמה</u>
          </strong>
        </li>
        <p>
          השימוש בפלטפורמה נועד לשימוש פרטי ואישי. <br />
          משתמש לא יעשה שימוש בפלטפורמה לרעה. בכלל זה המשתמש לא יעתיק וישתמש, או
          יאפשר לאחרים להשתמש, בכל אופן אחר או בכל דרך שהיא בתכנים מתוך
          הפלטפורמה, לכל מטרה שלא לצורך שימוש אישי, אלא אם כן ניתנה הסכמה מפורשת
          מצידנו, מראש ובכתב, ובכפוף לתנאי ההסכמה (ככל שניתנה). כמו כן, משתמש לא
          יציג תכנים מהפלטפורמה בכל דרך שהיא, ובכלל זה לא ישבש ו/או ישנה את
          התכנים בכל דרך שהיא. <br />
          בכלל זה, אינך רשאי לעשות שימוש בפלטפורמה באף אחד מן האופנים הבאים:
        </p>
        <ul>
          <li>
            שלא כדין או בניגוד לכל חוק או תקנה או הוראה סטטוטורית לרבות שימוש
            שיש בו או שעלול לעלות לכדי פגיעה בפרטיות, לשון הרע, פגיעה בחקיקת מגן
            על קטינים וחסרי ישע, הפרת זכות יוצרים, שימוש שעולה לכדי עוולה מסחרית
            וכל שימוש בלתי חוקי אחר;
          </li>
          <li>שאינו סביר ביחס למטרות שלהן נועדה הפלטפורמה;</li>
          <li>שנועד לפגוע או לשבש את פעולת הפלטפורמה; </li>
          <li>
            כדי ליצור מאגר מידע על-ידי הורדה ואחסון שיטתיים של כל או חלק מהתוכן,
            בין היתר באמצעות כלים אוטומטיים וטכנולוגיות לסריקת מידע, כריית מידע
            או אחזור אוטומטי או איסוף של מידע מתוך הפלטפורמה;{" "}
          </li>
          <li>
            שנועד להעביר או להנגיש בכל דרך אחרת, בקשר לפלטפורמה, כל וירוס,
            "תולעת" (worm), סוס טרויאני, באג, רוגלה (spyware), נוזקה (malware),
            או כל קוד מחשב, קובץ, יישום או תוכנה אחרים אשר עשויים להזיק, או
            נועדו להזיק לפעילות הפלטפורמה;
          </li>
          <li>או כדי להפר אי אלו מתנאי השימוש.</li>
        </ul>
        <p>
          אנו שומרים לעצמנו את הזכות להגביל, שלא לאפשר או לבטל שימוש בפלטפורמה
          או רכישת באמצעות הפלטפורמה אשר נחזים להיות שלא בהתאם לתנאי שימוש אלה,
          וכל פעולה בניגוד להוראות הסעיפים האמורים לעיל תהווה הפרה חמורה של תנאי
          השימוש ועלולה להוביל לפנייה לגורמים המוסמכים בחוק לטיפול בעבירות מסוג
          זה ולנקיטה בהליכים.
        </p>
        <li>
          <strong>
            <u>הגבלת אחריות</u>
          </strong>
        </li>
        <p>
          הרישום והשימוש בפלטפורמה נעשה באחריותך הבלעדית והמלאה. אנו עושים כמיטב
          יכולתנו כדי שהפלטפורמה תעמוד בסטנדרטים הגבוהים ביותר, אולם, השימוש
          ניתן לך כמו שהוא (AS IS), ללא כל התחייבות או אחריות מכל סוג שהיא,
          לרבות העדר התחייבות או אחריות כי הפלטפורמה תפעל ללא כל פגם או כי
          השימוש בה לא יגרום לך כל נזק. לא תהיה לך כל טענה, תביעה או דרישה בדבר
          השימוש בפלטפורמה, מאפייני הפלטפורמה, השירותים, המוצרים או התאמת
          הפלטפורמה לצרכים אינדיבידואלים של משתמשים שונים. בהתאם, לא נישא
          באחריות לנזק, פגם או תקלה, מכל סוג שהם, העלולים להיגרם כתוצאה מהורדת,
          מהתקנת או משימוש בפלטפורמה, או בשל אי יכולת שימוש בפלטפורמה, לרבות
          ומבלי לגרוע מכלליות האמור, אובדן נתונים, פגמים או תקלות במערכת ההפעלה
          או בתוכנה או כל בעיית חומרה או וירוסים או תוכנות עוינות או בעיה באיכות
          או במהירות השימוש בפלטפורמה. <br />
          לתשומת ליבך כי השימוש בפלטפורמה חשוף לסיכונים הכרוכים במערכות ממוחשבות
          ובמערכות תקשורת. אנו עושים כל מאמץ כדי למזער את מספר התקלות ולאפשר לך
          ליהנות מהפלטפורמה בכל עת. על אף האמור לעיל, אין אפשרות למנוע לחלוטין
          את התקלות והסיכונים האמורים. במקרה של הפסקות זמניות במתן שירותי
          תקשורת, שיבושים ותקלות אחרות בקווי תקשורת או זמינותן וזמני התגובה של
          המערכות הממוחשבות, שיבושים במידע ובנתונים או תקלות אחרות, נהיה פטורים
          מאחריות לכל נזק, הפסד והוצאה כלשהם או לשיבוש במידע, העלולים להיגרם לך
          כתוצאה מגורמים אלה. <br />
          בכפוף לכל דין, לא נהיה אחראיים בגין כל נזק או אבדן, לרבות נזק ישיר,
          עקיף, דמי נזיקין עונשים, נזק מקרי, מיוחד או תוצאתי, או כל נזק אחר מכל
          סוג ומין, לרבות בגין אובדן השימוש, אובדן נתונים או אובדן רווחים,
          השחתה, כשל אבטחה או גניבה של מידע, קיומם של וירוסים, תוכנות ריגול,
          אובדן הכנסה, קנסות, הפסדים או הוצאות שייגרמו כתוצאה: (א) משימוש
          בפלטפורמה , (ב) עקב אי-יכולת לעשות שימוש בפלטפורמה, או (ג) מכל הצגה או
          פרסום של מוצרים או תכנים המופיעים בפלטפורמה. <br />
          במקרים שבהם לא תתאפשר על-פי דין החרגה או הגבלת האחריות ביחס לנזקים
          מסוימים, האחריות שלנו תוגבל לאחריות המצומצמת ביותר על-פי החוק החל. אתה
          תישא בכל האחריות בגין טעות שביצעת בהקלדת נתונים לפלטפורמה, לרבות טעות
          בהקלדת הכתובת, בהקלדת פרטי האשראי או פרטי המרשם הרפואי. <br />
          המידע המופיע בפלטפורמה הינו מידע כללי ואין לראות בו משום ייעוץ רפואי
          או הבטחה לתוצאה או אחריות לאופן הפעילות של המוצרים. תמונות המוצרים
          והמידע אודות המוצרים נועדו להמחשה בלבד. בטרם שימוש במוצר, חובה לקרוא
          היטב את העלון למוצר ואת הנתונים המופיעים על גבי אריזת המוצר. בטרם
          שימוש במוצר, יש לוודא את ערכי ורכיבי המוצר המפורטים על גבי האריזה.
          איננו ספקים של ייעוץ רפואי ואם ברצונך לקבל ייעוץ רפואי עליך לפנות לבעל
          מקצוע מתאים. הפלטפורמה לא מהווה ולא נועדה בשום דרך להוות תחליף לייעוץ
          רפואי או מקצועי, לטיפול רפואי או אמצעי לאבחון, לריפוי או למניעה של כל
          מחלה או מצב רפואי. אנו ממליצים להתייעץ עם רופאים וגורמים רפואיים
          מקצועיים בכל ההחלטות הקשורות לרפואה ולבריאות, אשר מודעים לצרכים
          ולנסיבות האינדיבידואליים והמלאים של כל מקרה וכל משתמש ומדגישים כי
          מחובתך להיוועץ בבעל מקצוע מתאים לפני תחילת טיפול, בנוגע לאופן השימוש
          ותופעות לוואי. עליך לבדוק תמיד את העלון לצרכן המצורף למוצר שרכשת. ב
          <strong>
            <u>כל מצב חירום רפואי יש לפנות ללא דיחוי לקבלת טיפול רפואי</u>
          </strong>
          . <br />
          אנו לא נהיה אחראיים לכל מצב רפואי כתוצאה משימוש בפלטפורמה. <br />
          אם אתה מתחבר לפלטפורמה, ניגש אליה או משתמש בה כאפוטרופוס של משתמש
          מאושר, אתה מצהיר ומתחייב שאתה מורשה כדין לייצגו בקשר לתנאי שימוש אלה
          ולהתחייב להיות כפוף לתנאי שימוש אלה, ומתחייב בזאת בכל המצגים
          וההתחייבויות להלן מטעמך ומטעמו. דע לך, שאם תפר את תנאי השימוש, את החוק
          או את הזכויות של צד שלישי, ויגרם לנו נזק או הוצאה בשל כך, אתה תחויב
          לשפות אותנו.
        </p>
        <li>
          <strong>
            <u>קישורים לצדדים שלישיים</u>
          </strong>
        </li>
        <p>
          מידע ונתונים מסוימים המופיעים בפלטפורמה מתקבלים מצדדים שלישיים ונמסרו
          לנו על-ידם או מטעמם. מידע זה נמסר לך כמות שהוא (AS IS), ללא כל
          התחייבות או אחריות מכל סוג שהיא, באופן מפורש או מכללא. לא נוכל להתחייב
          כי מידע ונתונים אשר התקבלו מצדדים שלישיים כלשהם יהיו מדויקים או חפים
          מטעויות, ולא נוכל להיות ערבים לנכונותם או למהימנותם של מידע ונתונים
          אלה. בנוסף, בפלטפורמה יכולים להופיע קישורים לאתרים אחרים. לא נוכל לשאת
          באחריות לגבי תוכנם של אתרים אלו והימצאות הקישור בפלטפורמה אינה מהווה
          המלצה להיכנס לאתרים אלו. כניסה לאתרים אלו תהיה על פי שיקול דעתך העצמאי
          ואנו לא נהיה אחראיים לכל נזק, ישר או עקיף, כספי או אחר, שייגרם לך
          כתוצאה מכניסה לאתרים אלו או מהסתמכות על תוכן המופיע באתרים אלו.
        </p>
        <li>
          <strong>
            <u>קניין רוחני </u>
          </strong>
        </li>
        <p>
          הפלטפורמה, התוכן והשירותים, וכל תוצר הנובע משימוש בפלטפורמה וכל זכויות
          הקניין הרוחני הנוגעות להם, לרבות זכויות יוצרים, זכויות מוסריות וסימני
          המסחר (ביחד, "<strong>הקניין הרוחני</strong>") הם בבעלותנו או נמסרו
          לנו ברישיון ומוגנים על-ידי דיני הקניין הרוחני בישראל ובעולם. כל זכות
          שלא הוקנתה לך במפורש על-פי תנאי השימוש תישמר בידינו או בידי נותני
          הרישיון שלנו, לפי העניין. אתה אינך רשאי להעתיק, להפיץ, לשנות או לעשות
          יצירה נגזרת של כל תוכן הכפוף לזכויות הקניין הרוחני שלנו, בכל אופן או
          בכל אמצעי, אלא אם הדבר הותר במפורש בתנאי השימוש או על-פי הדין החל. אף
          הוראה בתנאי השימוש לא מהווה ויתור על הקניין הרוחני שלנו על-פי כל דין.{" "}
          <br />
          אנו מעניקים לך זכות מוגבלת, ניתנת לביטול, לא בלעדית ולא ניתנת להעברה,
          לעשות שימוש בפלטפורמה באופן אישי בלבד, בכפוף ובהתאם לתנאי שימוש אלו.
          אין זכויות או רישיונות נוספים המוענקים לך במשתמע או בכל דרך אחרת, למעט
          הזכויות שמוקנות לך במפורש בתנאי השימוש. <br />
          הפרת תנאי השימוש, החוק או הזכויות של צד שלישי, וגרימת נזק או פגיעה בנו
          או בזכויותינו בשל כך עלולה לחייב אותך. <br />
          באמצעות השימוש באפליקציה אתה מעניק לנו בזאת זכות ורישיון בלתי בלעדיים,
          ללא תמלוגים, בלתי תמידיים, בלתי חוזרים וניתנים להעברה (לרבות ויתור על
          כל זכויות מוסריות), לעשות שימוש, להעתיק, לאחסן, לשכפל ולעבד את המידע
          שלך, וליצור יצירות נגזרות של המידע שלך, בהתאם ל
          <span className="useTerms">מדיניות הפרטיות</span>. לא תידרש כל הסכמה
          נוספת, הודעה או פיצוי לך או לאחרים בגין שימוש במידע בהתאם לתנאי השימוש
          ו<span className="userTerms">למדיניות הפרטיות</span> כאמור.
        </p>
        <li>
          <strong>
            <u>עדכון, שדרוג ותמיכה טכנית</u>
          </strong>
        </li>
        <p>
          אנו שומרים לעצמנו את הזכות לבצע עדכון ושדרוג, לרבות אוטומטיים או כאלה
          שיחולו באופן מידי, על מנת לעמוד בדרישות כל דין, לשמר את אבטחת המידע
          בפלטפורמה, לשפר את פעילות הפלטפורמה או לכל צורך אחר שנראה לנכון.
          בנוסף, נהיה רשאים לעדכן ולשנות את הפלטפורמה ושירותיה, כולל היקפם
          וזמינותם, וזאת ללא צורך בהודעה מוקדמת. תנאי השימוש חלים על כל עדכון
          ושדרוג כאמור. לא תהיה לך כל טענה ו/או תביעה בגין שינויים או עדכונים.{" "}
          <br />
          במקרה של כל תקלה או שאלה בקשר לשימוש בפלטפורמה,{" "}
          <span className="useTerms">אנא פנה אלינו</span>. אנו נעשה את מירב
          המאמצים הסבירים לפתור תקלות, אולם אין באמור לעיל משום מצג או התחייבות
          לפרק הזמן שבו תקלה כאמור תיפתר או אם פתרון לתקלה כאמור יימצא. אנו לא
          מתחייבים לרמת שירות או לתיקון תקלות או לתמיכה טכנית מעבר לאמור בסעיף
          זה.
        </p>
        <li>
          <strong>
            <u>ניתוק והפסקת שירותים </u>
          </strong>
        </li>
        <p>
          אנו נהיה רשאים בכל עת לשנות את מבנה ותוכן הפלטפורמה, היקפה וזמינותה
          וזאת בלא צורך בהודעה מוקדמת. בנוסף, נהיה רשאים בכל עת לסיים או להשבית
          את הפלטפורמה, ללא הודעה מוקדמת, ובלבד שלא תחויב עבור הזמנה שלא סופקה
          בגין סיום או השבתה כאמור. במקרה שנחשוד שמשתמש פועל באופן מנוגד לתנאי
          השימוש או עשה שימוש בלתי ראוי בפלטפורמה, נהיה רשאים לחסום, למחוק או
          להגביל את חשבונו או את הגישה שלו לפלטפורמה. אין בחסימה, בהפסקה או
          בהגבלה כאמור, כדי להטיל עלינו אחריות או כדי לגרוע מחובת המשתמש לנהוג
          בהתאם לתנאי השימוש. אתה רשאי בכל עת להפסיק להשתמש בפלטפורמה ו/או למחוק
          את הפלטפורמה.
        </p>
        <li>
          <strong>
            <u>מועדון לקוחות</u>
          </strong>
          <ol>
            <li>
              <strong>
                <u>הצטרפות למועדון הלקוחות</u>
              </strong>
            </li>
            <p>
              <strong>
                אנו מציעים למשתמשים מסוימים, בהתאם לכללים שייקבעו על ידנו,
                להצטרף למועדון הלקוחות שלנו. ההצטרפות למועדון הלקוחות כפופה
                לרצון והסכמת המשתמש. החברות במועדון הלקוחות מקנה לחבריו הטבות,
                מבצעים והנחות ייחודיות מותאמי לקוח, יחד עם שירות משופר, ייעוץ
                רוקחי מותאם אישית ושירותים נוספים כפי שייקבעו על ידנו מעת לעת
                (ביחד,
              </strong>{" "}
              "ההטבות"). <br />
              <strong>
                חבר במועדון הלקוחות יהיה מי שמילא טופס ייעודי להצטרפות למועדון
                וקיבל את אישורנו אודות הצטרפותו למועדון הלקוחות. האחריות למילוי
                טופס ההצטרפות היא של המשתמש בלבד. בשלב זה אנו לא גובים תשלום
                בעבור הצטרפות למועדון הלקוחות. החברות במועדון הלקוחות
                <span className="highLighText">היא לשנתיים (24 חודשים) </span>
                ממועד ההצטרפות או החידוש, לפי העניין. ניתן יהיה לחדש את החברות
                במועדון הלקוחות בהתאם לתנאי מועדון הלקוחות כפי שיהיו בעת החידוש.
                חברות במועדון הלקוחות היא אישית ואיננה ניתנת להעברה. רק חבר
                מועדון הלקוחות בעצמו יהיה רשאי להנות מן ההטבות כפי שיהיו מעת
                לעת. <br />
                אנו שומרים את הזכות לבטל ו/או לשנות את תנאי החברות במועדון
                הלקוחות וההצטרפות אליו, לרבות את ההטבות, ובכלל זה לבטל את פעילות
                מועדון הלקוחות, על פי שיקול דעתנו הבלעדי ובהתאם להוראות כל דין.
              </strong>
            </p>
            <li>
              <strong>
                <u>ההטבות לחברי מועדון הלקוחות</u>
              </strong>
            </li>
            <p>
              <strong>
                אנו נציע מעת לעת הטבות ייעודיות ייחודיות לחברי מועדון הלקוחות.
                ההטבות תהיינה בתוקף בהתאם לפרסום ולתנאי ההטבה כפי שיפורסמו על
                ידנו. אנו נהיה רשאים, מעת לעת, לשנות את ההטבות, לרבות ביטולן
                והכול בהתאם ובכפוף לדין החל. הטבות אינן ניתנות לשינוי או המרה.
                מימוש ההטבות הינו אישי, ורק חבר מועדון הלקוחות בעצמו יוכל לממשן.
                אנו נהיה רשאים לקבוע כי הטבות מסוימות יינתנו או לא יינתנו לכל
                חברי מועדון הלקוחות, או לחברי מועדון לקוחות מסוימים או לסוג
                מסוים של חברים במועדון הלקוחות בלבד, הכל לפי פילוח כפי שנמצא
                לנכון באותה עת.
              </strong>
            </p>
            <li>
              <strong>
                <u>
                  מסירת פרטים אישיים בעת הצטרפות למועדון הלקוחות והסכמה לדיוור
                </u>
              </strong>
            </li>
            <p>
              <strong>
                חברי מועדון הלקוחות זכאים לקבל מידע תכני, שיווקי ופרסומי, לרבות
                מידע בגין הטבות, ומידע על שיתופי פעולה עם גופים אחרים באמצעות
                אמצעי המדיה השונים, בכפוף להוראות כל דין. הצטרפות למועדון
                הלקוחות מהווה הסכמת המשתמש לקבל מידע תכני, שיווקי ופרסומי, לרבות
                מידע בדבר פרסומים והטבות לחברי מועדון הלקוחות באמצעות אמצעי
                התקשורת אשר ימסור המשתמש (לרבות, דוא"ל וטלפון נייד). משתמש אשר
                אינו מעוניין בקבלת מידע כאמור מתבקש שלא לאשר את קבלת המידע על
                גבי טופס ההצטרפות.
              </strong>{" "}
              אנו סבורים כי חברי מועדון הלקוחות יימצאו ערך בקבלת מידע פרסומי זה,
              אולם ניתן לבחור בכל שלב לסרב לקבל מידע פרסומי נוסף. לתשומת ליבך כי
              אם תבחר שלא לקבל מידע פרסומי מאיתנו, לא נוכל לעדכן אותך בדבר
              ההטבות.
            </p>
            <li>
              <strong>
                <u>ביטול חברות במועדון הלקוחות</u>
              </strong>
            </li>
            <p>
              אנו שומרים על הזכות לבטל או
              <strong>
                {" "}
                להפסיק חברות במועדון הלקוחות (או לחילופין, לא לאפשר חידוש חברות)
                בכל מקרה בו לדעתנו המשתמש עשה מעשה בניגוד לתנאי שימוש אלו, השתמש
                לרעה בחברותו במועדון הלקוחות או פעל באופן לא ראוי או הוגן לניצול
                ההטבות.
              </strong>
            </p>
          </ol>
        </li>
        <li>
          <strong>
            <u>שונות ודין חל</u>
          </strong>
        </li>
        <p>
          האמור בתנאי שימוש אלה מנוסח למען הנוחות בלשון זכר אך מיועד גם ללשון
          נקבה. האמור בלשון יחיד – אף לשון רבים במשמע, וכן להיפך. <br />
          רישומי מחשב בקשר עם עיבוד הנתונים שלנו בדבר הפעולות המתבצעות דרך
          הפלטפורמה, יהוו ראיה לכאורה לנכונות הפעולות. <br />
          אנו נהיה רשאים להמחות, להעביר או להסב את הזכויות שלנו לפי תנאי שימוש
          אלה, או חלק מהן, ללא צורך בקבלת הסכמה כלשהי. אתה אינך רשאי לעשות כן
          ללא הסכמה מפורשת שלנו. אנו עשויים להתקשר, על פי שיקול דעתנו ובכפוף לכל
          דין, עם צדדים שלישיים שונים לצורך אספקת השירותים או חלקם. <br />
          איחור או הימנעות בעשיית מעשה שאנו רשאים לעשות לפי תנאי שימוש אלו או על
          פי כל דין ו/או בשימוש בזכות או בעמידה על זכות כלשהי הנתונה לנו על פי
          תנאי שימוש אלו או על פי כל דין, לא יחשבו כוויתור על זכות זו או השתק.
          כל ויתור שלנו על קיום זכות או הימנעות מעמידה על זכותה, לא יחשבו
          כוויתור, כהימנעות, כהשתק או כהיווצרות נוהג לגבי מקרים אחרים. <br />
          במקרה שהתחייבות או שהוראה כלשהי מתנאי השימוש תוכרז כבטלה או כבלתי
          ניתנת לאכיפה, ההתחייבות או ההוראה תישאר בתוקף בהיקף המירבי הניתן על פי
          דין ולא יהיה בכך כדי לפגוע ביתר ההתחייבויות או ביתר הוראות תנאי
          השימוש, אשר תעמודנה בתוקפן. <br />
          והשימוש בפלטפורמה וכן תנאי שימוש אלו כפופים לדיני מדינת ישראל. מקום
          השיפוט הבלעדי לכל עניין הנוגע לשימוש בפלטפורמה או לתנאי השימוש הינו
          בבית המשפט המוסמך בתל-אביב – יפו.
        </p>
        <p>עודכן לאחרונה: יולי, 2020</p>
        <li>
          <strong>
            <u>צור קשר</u>
          </strong>
        </li>
        <p>
          הקמנו מוקד שמטרתו לסייע, לתמוך ולתת מענה למשתמשים. אנא הרגש חופשי ל
          <strong className="useTerms">פנות אלינו</strong> בכל שאלה. שעות פעילות
          המוקד שלנו וכל הדרכים ליצור איתנו קשר זמינים בעמוד{" "}
          <strong className="useTerms">צור קשר</strong> באתר שלנו.
        </p>
      </ol>
      {/* </p> */}
    </div>
  );
}

export default TermsOfUseText;
