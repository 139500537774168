import React, { useEffect } from "react";
import { pageToImageMapping } from "./imageMapping";

import "./Gallery.scss";
import { useSelector } from "react-redux";

function Gallery() {
  const currentPage = useSelector(
    (state) => state.currentPageReducer.currentPage
  );

  useEffect(() => {
    if (!pageToImageMapping[currentPage].nextPages) {
      return;
    }
    for (let nextPage of pageToImageMapping[currentPage].nextPages) {
      const img = document.createElement("img");
      img.src = pageToImageMapping[nextPage].url;
    }
  }, [currentPage]);

  return (
    <>
      <img
        className="galleryImage"
        alt=""
        src={pageToImageMapping[currentPage].url}
      />
    </>
  );
}

export default Gallery;
