import * as Yup from "yup";

const phoneRegExp = /^0(5[^7])[0-9]{7}$/;

export const RegisterPageSchema = Yup.object().shape({
  userEmail: Yup.string()
    .email("נא להקליד כתובת דואר אלקטרוני תקינה")
    .required("שדה זה חובה למילוי"),
  userPhone: Yup.string()
    .required("שדה זה חובה למילוי")
    .min(6, "נא להקליד מספר טלפון תקין")
    .max(10, "נא להקליד מספר טלפון תקין")
    .matches(phoneRegExp, "נא להקליד מספר טלפון תקין"),
});
