import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core";
import PolicyText from "../PolicyAndUseTerms/PolicyText/PolicyText";
import closeModalIcon from "../../../assets/images/deleteDocumentIcon.svg";
import "./PolicyAndUseTerms.scss";
import TermsOfUseText from "./TermsOfUseText/TermsOfUseText";

function PolicyAndUseTerms(props) {
  const [tabNumber, setTabNumner] = useState(0);

  const tabClasses = makeStyles((theme) => ({
    root: {
      font:
        "var(--font-style-normal) normal var(--font-weight-bold) var(--font-size-20) / var(--line-spacing-30) var(--font-family-primary)",
      letterSpacing: "var(--character-spacing-0)",
      textAlign: "center",
      color: "#909090",
      opacity: "1",
      "&:focus": {
        color: "var(--color-primary)",
      },
    },
  }))();

  return (
    <div>
      <button
        type="button"
        onClick={() => props.onFinish()}
        className="closeModalButton"
      >
        <img alt="" src={closeModalIcon} />
      </button>
      <Tabs
        className="policyAndTermsOfUse"
        value={tabNumber}
        indicatorColor="primary"
        textColor="primary"
        onChange={(event, newTab) => setTabNumner(newTab)}
        aria-label="disabled tabs example"
      >
        <Tab classes={tabClasses} label="מדיניות פרטיות" />
        <Tab classes={tabClasses} label="תנאי שימוש" />
      </Tabs>
      <div className="policyAndUseTexts">
        {tabNumber === 0 ? <PolicyText /> : <TermsOfUseText />}
      </div>
    </div>
  );
}

export default PolicyAndUseTerms;
