import { createSlice } from "@reduxjs/toolkit";

/**
 * "createSlice" wraps reducer function with produce from the Immer library.
 * This means you can write code that "mutates" the state inside the reducer,
 * and Immer will safely return a correct immutably updated result.
 */
const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: {
    isUserDetailsFinished: false,
    error: null,
  },
  reducers: {
    updateUserDetailsRequest(state, action) {},
    userDetailsSuccess(state, action) {
      state.isUserDetailsFinished = true;
    },
    userDetailsFail(state, action) {
      state.error = action.payload;
      state.isUserDetailsFinished = true;
    },
    resetError(state, action) {
      state.error = null;
      state.isUserDetailsFinished = false;
    },
  },
});

export const {
  updateUserDetailsRequest,
  userDetailsSuccess,
  userDetailsFail,
  resetError,
} = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
