import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import doctorImage from "../../../assets/images/doctorImage.svg";
import recycleImage from "../../../assets/images/recycleImage.svg";
import twoPeopleImage from "../../../assets/images/twoPeopleImage.svg";
import labImage from "../../../assets/images/labImage.svg";
import { WELCOME_PAGE } from "../../../constants";
import { homePageTexts } from "../../../texts";
import { setCurrentPage } from "../../../store/slices/currentPageSlice";

import "./HomePage.scss";
import { useDispatch } from "react-redux";

function HomePage(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPage(WELCOME_PAGE));
  }, []);

  return (
    <div className="homePage">
      <div className="homeWelcomeText">{homePageTexts.homePageMainText}</div>
      <div className="homeSloganText">{homePageTexts.homePageSlogan}</div>
      <div className="services">
        <div className="servicesText personalAdvice">
          <img alt="" src={twoPeopleImage} />
          <div>{homePageTexts.homePagePersonalServiceText}</div>
        </div>
        <div className="servicesText pharmacistAdvice">
          <img alt="" src={doctorImage} />
          <div>{homePageTexts.homePagePharmacistAdviceText}</div>
        </div>
        <div className="servicesText">
          <img alt="" src={labImage} />
          <div>{homePageTexts.homePageProductInnovationText}</div>
        </div>
        <div className="servicesText">
          <img alt="" src={recycleImage} />
          <div>{homePageTexts.homePageOffersText}</div>
        </div>
      </div>
      <div className="joinClub">
        <Link className="joinClubText" to="/register">
          {homePageTexts.joinTheClubLinkText}
        </Link>
      </div>
      <div className="registeredCustomers">
        <Link className="registeredCustomersLink" to="/login">
          {homePageTexts.registeredCustomersLinkText}
        </Link>
      </div>
    </div>
  );
}

export default HomePage;
