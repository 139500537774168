export const routes = {
  homePage: "/",
  loginPage: "/login",
  registerPage: "/register",
  loginVerificationPage: "/login/verification",
  registerVerificationPage: "/register/verification",
  userDetailsPage: "/user-details",
  uploadDocumentPage: "/upload-documents",
  additionalDetailsPage: "/additional-details",
  finishProcessPage: "/finish-process",
  errorNotification: "/error-notification",
  successNotification: "/success-notification",
  existingClubMemberNotification: "/existing-member-notification",
  signatureBox: "/signature-box",
};
