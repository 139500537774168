import { eventChannel, END } from "redux-saga";
import {
  call,
  fork,
  take,
  put,
  select,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import {
  deleteSingleDocument,
  getUploadedDocuments,
  getUser,
  updateAdditionalData,
  updateFinalData,
  updatePersonalData,
  uploadSingleDocument,
} from "../../apiCalls";
import {
  mapClientModelToServerModel,
  mapServerModelToClientModel,
} from "../../mappingForServer";
import { hideLoader, showLoader } from "../slices/loaderSlice";
import {
  updateUserAdditionalDetailsRequest,
  userAdditionalDetailsFail,
  userAdditionalDetailsSuccess,
} from "../slices/userAdditionalDetailsSlice";
import {
  updateUserDetailsRequest,
  userDetailsFail,
  userDetailsSuccess,
} from "../slices/userDetailsSlice";
import {
  updateUserFinishProcessRequest,
  userFinishProcessFail,
  userFinishProcessSuccess,
} from "../slices/userFinishProcessSlice";
import { fetchUserRequest, updateUser } from "../slices/userSlice";
import {
  deleteDocument,
  updateProgress,
  uploadDocumentsRequest,
  deleteDocumentsRequest,
  uploadFail,
  uploadSuccess,
  fetchUploadedDocumentsRequest,
} from "../slices/userUploadDocumentsSlice";

export const getApiToken = (state) => state.userCredentialsReducer.apiToken;

function* loadUser(action) {
  try {
    yield put(showLoader());
    const apiToken = yield select(getApiToken);
    const response = yield call(getUser, apiToken);
    const user = mapServerModelToClientModel(response.data);
    yield put(updateUser(user));
  } catch (error) {
  } finally {
    yield put(hideLoader());
  }
}

function* storeUserPersonalData(action) {
  try {
    yield put(showLoader());
    const apiToken = yield select(getApiToken);
    const serverUser = mapClientModelToServerModel(action.payload);
    yield call(updatePersonalData, serverUser, apiToken);
    yield put(updateUser(action.payload));
    yield put(userDetailsSuccess());
  } catch (error) {
    yield put(userDetailsFail(error));
  } finally {
    yield put(hideLoader());
  }
}

function* storeUserAdditionalData(action) {
  try {
    yield put(showLoader());
    const apiToken = yield select(getApiToken);
    const serverUser = mapClientModelToServerModel(action.payload);
    yield call(updateAdditionalData, serverUser, apiToken);
    yield put(updateUser(action.payload));
    yield put(userAdditionalDetailsSuccess());
  } catch (error) {
    yield put(userAdditionalDetailsFail(error));
  } finally {
    yield put(hideLoader());
  }
}

function* storeUserFinalData(action) {
  try {
    yield put(showLoader());
    const apiToken = yield select(getApiToken);
    const serverUser = mapClientModelToServerModel(action.payload);
    yield call(updateFinalData, serverUser, apiToken);
    yield put(updateUser(action.payload));
    yield put(userFinishProcessSuccess());
  } catch (error) {
    yield put(userFinishProcessFail(error));
  } finally {
    yield put(hideLoader());
  }
}

function* watchOnProgress(fileName, channel) {
  while (true) {
    const percentage = yield take(channel);
    yield put(updateProgress({ fileName: fileName, percentage: percentage }));
  }
}

function* uploadUserDocuments(action) {
  const apiToken = yield select(getApiToken);

  for (const file of action.payload.files) {
    // Stolen from here: https://stackoverflow.com/questions/40402744/redux-saga-axios-and-progress-event
    try {
      let emit;
      const channel = eventChannel((emitter) => {
        emit = emitter;
        return () => {};
      });
      yield fork(watchOnProgress, file.name, channel);
      const response = yield call(
        uploadSingleDocument,
        file,
        action.payload.category,
        (percentage) => {
          emit(percentage);
          if (percentage === 100) {
            emit(END);
          }
        },
        apiToken
      );
      yield put(
        uploadSuccess({
          fileName: file.name,
          id: response.data.id,
          category: action.payload.category,
        })
      );
    } catch (error) {
      yield put(uploadFail({ fileName: file.name }));
    }
  }
}

function* deleteUserDocuments(action) {
  try {
    const apiToken = yield select(getApiToken);
    yield call(deleteSingleDocument, action.payload.id, apiToken);
    yield put(deleteDocument(action.payload.fileName));
  } catch (error) {
  }
}

function* loadUploadedDocuments(action) {
  try {
    yield put(showLoader());
    const apiToken = yield select(getApiToken);
    const response = yield call(getUploadedDocuments, apiToken);
    for (let i = 0; i < response.data.length; i++) {
      yield put(
        uploadSuccess({
          fileName: response.data[i].file_name,
          id: response.data[i].id,
          category: response.data[i].file_category,
        })
      );
    }
  } catch (error) {
  } finally {
    yield put(hideLoader());
  }
}

function* userSaga() {
  yield takeLatest(fetchUserRequest.toString(), loadUser);
  yield takeLatest(updateUserDetailsRequest.toString(), storeUserPersonalData);
  yield takeLatest(
    updateUserAdditionalDetailsRequest.toString(),
    storeUserAdditionalData
  );
  yield takeLatest(
    updateUserFinishProcessRequest.toString(),
    storeUserFinalData
  );
  yield takeEvery(uploadDocumentsRequest.toString(), uploadUserDocuments);
  yield takeEvery(deleteDocumentsRequest.toString(), deleteUserDocuments);
  yield takeLatest(
    fetchUploadedDocumentsRequest.toString(),
    loadUploadedDocuments
  );
}

export default userSaga;
