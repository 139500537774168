import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_DETAILS_PAGE } from "../../../constants";
import { Formik } from "formik";
import UserDetailsForm from "./UserDetailsForm/UserDetailsForm";
import { UserDetailsSchema } from "./userDetailsSchema";
import { useHistory } from "react-router-dom";
import { userDetailsPageTexts } from "../../../texts";
import { routes } from "../../../routes";
import { updateUserDetailsRequest } from "../../../store/slices/userDetailsSlice";
import { setCurrentPage } from "../../../store/slices/currentPageSlice";

import "./UserDetailsPage.scss";

function UserDetailsPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userReducer);
  const isUserDetailsFinished = useSelector(
    (state) => state.userDetailsReducer.isUserDetailsFinished
  );
  const error = useSelector((state) => state.userDetailsReducer.error);

  useEffect(() => {
    dispatch(setCurrentPage(USER_DETAILS_PAGE));
  }, []);

  useEffect(() => {
    if (isUserDetailsFinished && !error) {
      history.push(routes.uploadDocumentPage);
    }
  }, [isUserDetailsFinished, error, history]);

  const submit = (values) => {
    dispatch(updateUserDetailsRequest(values));
  };
  
  return (
    <div className="userDetails">
      <div className="userDetailsText">
        {userDetailsPageTexts.userDetailsPageMainText}
      </div>
      <Formik
        onSubmit={submit}
        children={(props) => <UserDetailsForm {...props} />}
        initialValues={userDetails || {}}
        enableReinitialize
        validationSchema={UserDetailsSchema}
      />
    </div>
  );
}
export default UserDetailsPage;
