import * as Yup from "yup";

const textInputRegex = /^\D+$/;
const phoneRegex = /^0(5[^7])[0-9]{7}$/;
const idRegex = /^[0-9]{9}$/g;

const requiredAccreditedDetails = (requiredText) => ({
  is: (val) => val && val.length > 0,
  then: Yup.string().required(requiredText),
});

export const UserDetailsSchema = Yup.object().shape({
  fullname: Yup.string()
    .matches(textInputRegex, "נא להקליד שם תקין")
    .max(48, "נא להקליד שם עד 48 תווים"),
  phoneNumber: Yup.string()
    .required("שדה זה הוא חובה")
    .matches(phoneRegex, "נא להקליד מספר תקין"),
  socialNumber: Yup.string()
    .required("שדה זה הוא חובה")
    .matches(idRegex, "נא להקליד מספר תעודת זהות תקין"),
  email: Yup.string()
    .email("נא להקליד אימייל תקין")
    .required("שדה זה הוא חובה"),
  birthdate: Yup.string().required(),
  gender: Yup.string().required(),
  city: Yup.string().required(),
  street: Yup.string()
    .matches(textInputRegex, "נא להקליד שם רחוב תקין"),
  streetNumber: Yup.string(),
  accreditedFullname: Yup.string().matches(textInputRegex, "נא להקליד שם תקין").nullable(),
  accreditedSocialNumber: Yup.string()
    .matches(idRegex, "נא להקליד מספר תעודת זהות תקין")
    .when("accreditedFullname", requiredAccreditedDetails("שדה זה הוא חובה")).nullable(),
  accreditedPhone: Yup.string()
    .matches(phoneRegex, "נא להקליד מספר תקין")
    .when("accreditedFullname", requiredAccreditedDetails("שדה זה הוא חובה")).nullable(),
});
