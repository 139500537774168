import { createSlice } from "@reduxjs/toolkit";

const defaultUserState = {
  phoneNumber: "",
  email: "",
  allowedAmount: "",
  medicalField: "",
  useCase: "",
  city: "",
  licenseNumber: "",
  pharmacistAdvice: "yes",
  preferredVarieties: [],
  isApproveCommercialContent: false,
  morningDelivery: false,
  noonDelivery: false,
  eveningDelivery: false,
  order_addons: "",
  isPolicyAndUsageAccepted: false,
  birthdate: "",
  fullname: "",
  socialNumber: "",
  street: "",
  streetNumber: "",
  accreditedFullname: "",
  accreditedSocialNumber: "",
  accreditedPhone: "",
  caretakerFullname: "",
};

/**
 * "createSlice" wraps reducer function with produce from the Immer library.
 * This means you can write code that "mutates" the state inside the reducer,
 * and Immer will safely return a correct immutably updated result.
 */
const userSlice = createSlice({
  name: "user",
  initialState: defaultUserState,
  reducers: {
    fetchUserRequest(state, action) {},
    updateUser(state, action) {
      Object.assign(state, action.payload);
    },
    resetUser(state, action) {
      return defaultUserState;
    },
  },
});

export const { updateUser, fetchUserRequest } = userSlice.actions;
export default userSlice.reducer;
