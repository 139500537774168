/* eslint-disable no-use-before-define */
import React from "react";
// import Chip from '@material-ui/core/Chip';
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import { InputBase } from "@material-ui/core";
import "./MultiInput.scss";

export const MultiInput = (props) => {
  const {
    value: chosenOptions,
    id,
    touched,
    errors,
    onChange,
    label,
    required,
  } = props;

  const tooltipClasses = makeStyles((theme) => ({
    arrow: {
      color: "white",
    },
    tooltip: {
      font:
        "var(--font-style-normal) normal var(--font-weight-normal) 12px/22px var(--font-family-primary)",
      backgroundColor: "white",
      color: "#F2415C",
      boxShadow: "0px 3px 10px #00000029",
    },
  }))();

  const chipClasses = makeStyles((theme) => ({
    deleteIcon: {
      marginLeft: "0.5rem",
    },
    root: {
      marginRight: "0.5rem",
      marginTop: "0.5rem",
    },
  }))();

  const menuClasses = makeStyles((theme) => ({
    list: {
      padding: "0!important",
    },
  }))();

  const selectClasses = makeStyles((theme) => ({
    icon: {
      left: "0.5rem",
      right: "auto",
    },
    select: {
      paddingRight: "1rem",
    },
  }))();

  return (
    <FormControl className="multiInputComplete">
      <label htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
        <span>{chosenOptions.length}/3</span>
      </label>
      <Select
        classes={selectClasses}
        id={id}
        multiple
        MenuProps={{
          variant: "menu",
          getContentAnchorEl: null,
          elevation: 1,
          classes: menuClasses,
        }}
        value={chosenOptions}
        onChange={(event) => {
          let newValue = event.target.value;
          if (newValue.length > 3) {
            newValue.shift();
          }
          onChange(newValue);
        }}
        input={<InputBase />}
        variant="outlined"
        placeholder="החלף זנים מועדפים"
        renderValue={(selected) => "החלף זנים מועדפים"}
      >
        {preferredVarieties.map((variety) => (
          <MenuItem key={variety.value} value={variety.value}>
            <Checkbox checked={chosenOptions.indexOf(variety.value) > -1} />
            <ListItemText primary={variety.title} />
          </MenuItem>
        ))}
      </Select>

      <div>
        {chosenOptions.map((v) => (
          <Chip
            classes={chipClasses}
            key={v}
            label={v}
            size="small"
            onDelete={() => {
              onChange(
                chosenOptions.filter((singleValue) => singleValue !== v)
              );
            }}
          />
        ))}
      </div>
      {touched[id] && !!errors[id] && (
        <div className="errorMessage">
          <Tooltip
            classes={tooltipClasses}
            arrow
            title={errors[id]}
            placement="top"
          >
            <span className="errorIcon">i</span>
          </Tooltip>
        </div>
      )}
    </FormControl>
  );
};

const preferredVarieties = [
  { title: "רומא - IMC", value: "רומא - IMC" },
  { title: "ניצן - שיח", value: "ניצן - שיח" },
  { title: "כנרית - שיח", value: "כנרית - שיח" },
  { title: "IMC - SPANISH IMPORT", value: "IMC - SPANISH IMPORT" },
  { title: "IMC- LONDON", value: "IMC- LONDON" },
  { title: "IMC - DQ ", value: "IMC - DQ" },
  { title: "CURE E.P - בטר", value: "CURE E.P - בטר" },
  { title: "צונמי - שאיפה לחיים", value: "צונמי - שאיפה לחיים" },
  { title: "אמרלד - בזלת", value: "אמרלד - בזלת" },
  { title: "כרמל - פרמוקן", value: "כרמל - פרמוקן" },
  { title: "מגידו - פרמוקן", value: "מגידו - פרמוקן" },
  { title: "אופל - בזלת", value: "אופל - בזלת" },
  { title: "גרוזליים - פרמוקן", value: "גרוזליים - פרמוקן" },
  { title: "זטא - קאנדוק", value: "זטא - קאנדוק" },
];
