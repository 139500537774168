import { createSlice } from "@reduxjs/toolkit";

/**
 * "createSlice" wraps reducer function with produce from the Immer library.
 * This means you can write code that "mutates" the state inside the reducer,
 * and Immer will safely return a correct immutably updated result.
 */
const registerSlice = createSlice({
  name: "register",
  initialState: {
    isRegisterFinished: false,
    error: null,
  },
  reducers: {
    registerRequest(state, action) {},
    registerSuccess(state, action) {
      state.isRegisterFinished = true;
    },
    registerFail(state, action) {
      state.error = action.payload;
      state.isRegisterFinished = true;
    },
    resetError(state, action) {
      state.error = null;
      state.isRegisterFinished = false;
    },
  },
});

export const {
  registerRequest,
  registerSuccess,
  registerFail,
  resetError,
} = registerSlice.actions;

export default registerSlice.reducer;
