import React from "react";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import "./TextareaInput.scss";

function TextareaInput(props) {
  const {
    value,
    id,
    touched,
    errors,
    onChange,
    label,
    type,
    required,
    className,
    disabled,
  } = props;

  const tooltipClasses = makeStyles((theme) => ({
    arrow: {
      color: "white",
    },
    tooltip: {
      font:
        "var(--font-style-normal) normal var(--font-weight-normal) 12px/22px var(--font-family-primary)",
      backgroundColor: "white",
      color: "#F2415C",
      boxShadow: "0px 3px 10px #00000029",
    },
  }))();

  return (
    <FormControl
      className={`textareaInput ${
        touched[id] && !!errors[id] ? "hasError" : ""
      } ${className} ${disabled ? "disabled" : ""}`}
    >
      <label htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <InputBase
        placeholder={props.placeholder}
        className="textareaStyle"
        rows={props.rows}
        multiline={true}
        disabled={disabled}
        fullWidth={true}
        type={type}
        value={value}
        onChange={onChange}
        id={id}
      />
      {touched[id] && !!errors[id] && (
        <div className="errorMessage">
          <Tooltip
            classes={tooltipClasses}
            arrow
            title={errors[id]}
            placement="top"
          >
            <span className="errorIcon">i</span>
          </Tooltip>
        </div>
      )}
    </FormControl>
  );
}

export default TextareaInput;
