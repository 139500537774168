import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faqTexts } from "../../texts";
import QuestionAndAnswer from "../QuestionAndAnswer/QuestionAndAnswer";
import { hideFaq } from "../../store/slices/faqSlice";
import closeFaqIcon from "../../assets/images/deleteDocumentIcon.svg";
import { questionsAndAnswersWrapperTexts } from "../../texts";
import "./QuestionsAndAnswersWrapper.scss";

function QuestionsAndAnswersWrapper(props) {
  const [animationTrigger, setAnimationTrigger] = useState(false);
  const isFaqShown = useSelector((state) => state.faqReducer.isFaqShown);
  const questionsAndAnswers = faqTexts;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFaqShown) setAnimationTrigger(false);
  }, [isFaqShown]);

  const qAndAList = useMemo(() => {
    return questionsAndAnswers.map((item, index) => (
      <QuestionAndAnswer key={index} singleItem={item} />
    ));
  }, [questionsAndAnswers]);

  return (
    <>
      {isFaqShown && (
        <div
          className={`qustionsAndAnswersWrapper ${
            animationTrigger && "closingAnimation"
          }`}
        >
          <button
            type="button"
            onClick={() => {
              setAnimationTrigger(true);
              setTimeout(() => dispatch(hideFaq()), 500);
            }}
            className="closeQAndA"
          >
            <img alt="" src={closeFaqIcon} />
          </button>
          <div className="qAndAHeaderText">
            {questionsAndAnswersWrapperTexts.headerText}
          </div>
          <div className="qAndAContent">{qAndAList}</div>
        </div>
      )}
    </>
  );
}

export default QuestionsAndAnswersWrapper;
