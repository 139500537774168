import React from "react";
import addDocumentIcon from "../../../../assets/images/addIcon.svg";
import uploadDocumentImage from "../../../../assets/images/uploadDocument.svg";
import { fileUploadInputTexts } from "../../../../texts";

function FileUploadInput({ id, text, uploadFile, multiple }) {
  return (
    <>
      <img className="uploadDocumentImage" src={uploadDocumentImage} alt="" />
      <div className="addDocument">
        <label htmlFor={id} className="uploadDocumentLink">
          <img className="addFileIcon" src={addDocumentIcon} alt="" />
          {text}
        </label>
        <input
          onChange={(event) => uploadFile(event.target.files)}
          id={id}
          className="uploadDocumentInput"
          type="file"
          multiple={multiple}
          accept="image/jpeg,image/png,application/pdf"
        />
      </div>
      <div className="allowedDocumentFormats">
        {fileUploadInputTexts.allowedFormatsText}
      </div>
    </>
  );
}

export default FileUploadInput;
