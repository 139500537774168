import { createSlice } from "@reduxjs/toolkit";

/**
 * "createSlice" wraps reducer function with produce from the Immer library.
 * This means you can write code that "mutates" the state inside the reducer,
 * and Immer will safely return a correct immutably updated result.
 */
const loginSlice = createSlice({
  name: "login",
  initialState: {
    isExistingCustomer: false,
    isLoginFinished: false,
    error: null,
  },
  reducers: {
    loginRequest(state, action) {},
    loginSuccess(state, action) {
      state.isLoginFinished = true;
      state.isExistingCustomer = true;
    },
    loginFail(state, action) {
      state.error = action.payload;
      state.isLoginFinished = true;
    },
    resetError(state, action) {
      state.error = null;
      state.isLoginFinished = false;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFail,
  resetError,
} = loginSlice.actions;

export default loginSlice.reducer;
