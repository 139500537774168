import { usages } from "./components/pages/AdditionalDetailsPage/AdditionalDetailsForm/AdditionalDetailsForm";

const serverToClientMapping = {
  social_number: "socialNumber",
  phone_number: "phoneNumber",
  accredited_fullname: "accreditedFullname",
  accredited_phone: "accreditedPhone",
  accredited_social_number: "accreditedSocialNumber",
  allowed_amount: "allowedAmount",
  caretaker_fullname: "caretakerFullname",
  city: "city",
  digital_signature: "digitalSignature",
  email: "email",
  full_name: "fullname",
  gender: "gender",
  is_approve_commercial_content: "isApproveCommercialContent",
  licence_expiration_date: "licenceExpirationDate",
  license_number: "licenseNumber",
  medical_field: "medicalField",
  order_addons: "orderAddons",
  pharmacist_advice: "pharmacistAdvice",
  prefered_varieties: "preferredVarieties",

  is_policy_and_usage_accepted: "isPolicyAndUsageAccepted",
};

const clientToServerMapping = {};

for (const key in serverToClientMapping) {
  clientToServerMapping[serverToClientMapping[key]] = key;
}

const mapUseCaseServerToClient = (serverModel, clientModel) => {
  if (serverModel["use_case"] && serverModel["other_reasons_for_usecase"]) {
    clientModel["otherReasonsForUsecase"] = serverModel["use_case"];
  } else {
    clientModel["use_case"] =
      serverModel["use_case"];
  }
};

const mapbirthdateServerToClient = (serverModel, clientModel) => {
  clientModel["birthdate"] = "";
  if (serverModel["birthdate"]) {
    clientModel["birthdate"] = serverModel["birthdate"]
      .split("-")
      .reverse()
      .join("/");
  }
};

const mapLicenceExpirationDateServerToClient = (serverModel, clientModel) => {
  clientModel["licenceExpirationDate"] = "";
  if (serverModel["licence_expiration_date"]) {
    clientModel["licenceExpirationDate"] = serverModel[
      "licence_expiration_date"
    ]
      .split("-")
      .reverse()
      .join("/");
  }
};

const mapDeliveryTimeServerToClient = (serverModel, clientModel) => {
  clientModel["morningDelivery"] = false;
  clientModel["noonDelivery"] = false;
  clientModel["eveningDelivery"] = false;

  if (serverModel["delivery_time"]) {
    const deliveryTime = serverModel["delivery_time"].split(",");

    if (deliveryTime.includes("בוקר")) {
      clientModel["morningDelivery"] = true;
    }
    if (deliveryTime.includes("צהריים")) {
      clientModel["noonDelivery"] = true;
    }
    if (deliveryTime.includes("ערב")) {
      clientModel["eveningDelivery"] = true;
    }
  }
};

const mapAddressServerToClient = (serverModel, clientModel) => {
  // let streetRegex = /\d/;
  if (serverModel["address"]) {
    const address = serverModel["address"].split(" ");
    if (address.length > 1) {
      const streetNumber = address.pop();
      const containsDigit = /\d/.test(streetNumber);
      if (containsDigit) {
        clientModel["streetNumber"] = streetNumber
      }
      else {
        address.push(streetNumber);
      }
      clientModel["street"] = address.join(" ");
    } else {
      clientModel["street"] = address;
    }
  }
};

const mapPreferredVarietiesServerToClient = (serverModel, clientModel) => {
  clientModel["preferredVarieties"] = [];

  if (serverModel["first_priority_breed"]) {
    clientModel["preferredVarieties"].push(serverModel["first_priority_breed"]);
  }
  if (serverModel["second_priority_breed"]) {
    clientModel["preferredVarieties"].push(
      serverModel["second_priority_breed"]
    );
  }
  if (serverModel["third_priority_breed"]) {
    clientModel["preferredVarieties"].push(serverModel["third_priority_breed"]);
  }
};

const mapUseCaseClientToServer = (clientModel, serverModel) => {
  serverModel["use_case"] = "";
  serverModel["other_reasons_for_usecase"] = "";
  if (clientModel["useCase"]) {
    serverModel["use_case"] = clientModel["useCase"];
  } else {
    serverModel["other_reasons_for_usecase"] =
      clientModel["otherReasonsForUsecase"];
  }
};

const mapLicenceExpirationDateClientToServer = (clientModel, serverModel) => {
  if (clientModel["licenceExpirationDate"]) {
    serverModel["licence_expiration_date"] = clientModel["licenceExpirationDate"]
    .split("/")
    .reverse()
    .join("-");
  }
};

const mapbirthdateClientToServer = (clientModel, serverModel) => {
  if (clientModel["birthdate"]) {
    serverModel["birthdate"] = clientModel["birthdate"]
        .split("/")
        .reverse()
        .join("-");
  }
};

const mapDeliveryTimeClientToServer = (clientModel, serverModel) => {
  serverModel["delivery_time"] = [];
  if (clientModel["morningDelivery"]) {
    serverModel["delivery_time"].push("בוקר");
  }
  if (clientModel["noonDelivery"]) {
    serverModel["delivery_time"].push("צהריים");
  }
  if (clientModel["eveningDelivery"]) {
    serverModel["delivery_time"].push("ערב");
  }
  serverModel["delivery_time"] = serverModel["delivery_time"].join(",");
};

const mapAddressClientToServer = (clientModel, serverModel) => {
  if (clientModel["street"] && clientModel["streetNumber"]) {
    serverModel["address"] = `${clientModel["street"]} ${clientModel["streetNumber"]}`;
  }
  else {
    if (clientModel["street"]) {
    serverModel["address"] = `${clientModel["street"]}`;
    }
  }
};

const mapPreferredVarietiesClientToServer = (clientModel, serverModel) => {
  const varieties = clientModel["preferredVarieties"] || [];
  serverModel["first_priority_breed"] = varieties[0] || "";
  serverModel["second_priority_breed"] = varieties[1] || "";
  serverModel["third_priority_breed"] = varieties[2] || "";
};

export const mapServerModelToClientModel = (serverModel) => {
  const clientModel = {};

  for (const key in serverModel) {
    if (serverToClientMapping[key] !== undefined) {
      if(serverToClientMapping[key] == 'caretakerFullname' ||  serverToClientMapping[key] == 'digitalSignature') {
        clientModel[serverToClientMapping[key]] = null;
      } else {
        clientModel[serverToClientMapping[key]] = serverModel[key];
      }
    }
  }
  clientModel["pharmacistAdvice"] = clientModel["pharmacistAdvice"]
    ? "yes"
    : "no";

  mapDeliveryTimeServerToClient(serverModel, clientModel);

  mapAddressServerToClient(serverModel, clientModel);

  mapPreferredVarietiesServerToClient(serverModel, clientModel);

  mapbirthdateServerToClient(serverModel, clientModel);

  mapLicenceExpirationDateServerToClient(serverModel, clientModel);

  mapUseCaseServerToClient(serverModel, clientModel);

  return clientModel;
};

export const mapClientModelToServerModel = (clientModel) => {
  const serverModel = {};

  for (const key in clientModel) {
    if (clientToServerMapping[key] !== undefined) {
      serverModel[clientToServerMapping[key]] = clientModel[key];
    }
  }

  serverModel["pharmacist_advice"] = serverModel["pharmacist_advice"] === "yes";

  mapDeliveryTimeClientToServer(clientModel, serverModel);

  mapAddressClientToServer(clientModel, serverModel);

  mapPreferredVarietiesClientToServer(clientModel, serverModel);

  mapbirthdateClientToServer(clientModel, serverModel);

  mapLicenceExpirationDateClientToServer(clientModel, serverModel);

  mapUseCaseClientToServer(clientModel, serverModel);

  return serverModel;
};
