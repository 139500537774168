import { call, put, select, takeLatest } from "redux-saga/effects";
import { loginFail, loginRequest, loginSuccess } from "../slices/loginSlice";
import { hideLoader, showLoader } from "../slices/loaderSlice";
import { login, register, verify } from "../../apiCalls";
import { updateCredentials } from "../slices/userCredentialsSlice";
import {
  registerFail,
  registerRequest,
  registerSuccess,
} from "../slices/registerSlice";
import {
  verificationRequest,
  verificationSuccess,
  verificationFail,
} from "../slices/verificationSlice";
import { fetchUserRequest, updateUser } from "../slices/userSlice";
import {
  API_LOGIN_ERROR,
  API_REGISTER_ERROR,
  API_VERIFICATION_BAD_CREDENTIALS_ERROR,
  API_VERIFICATION_ERROR,
  API_VERIFICATION_MISSING_CODE_ERROR,
  API_USER_IS_CLUB_MEMBER_ERROR,
} from "../../constants";

function* loginUser(action) {
  try {
    yield put(showLoader());
    yield call(login, action.payload.userId, action.payload.userPhone);
    yield put(updateCredentials(action.payload));
    yield put(loginSuccess());
  } catch (error) {
    yield put(loginFail(API_LOGIN_ERROR));
  } finally {
    yield put(hideLoader());
  }
}

function* registerUser(action) {
  try {
    yield put(showLoader());
    yield call(register, action.payload.userEmail, action.payload.userPhone);
    yield put(updateCredentials(action.payload));
    yield put(
      updateUser({
        phoneNumber: action.payload.userPhone,
        email: action.payload.userEmail,
      })
    );
    yield put(registerSuccess());
  } catch (error) {
    yield put(registerFail(API_REGISTER_ERROR));
  } finally {
    yield put(hideLoader());
  }
}

export const getUserPhone = (state) => state.userCredentialsReducer.userPhone;

function* verifyUser(action) {
  try {
    const userPhone = yield select(getUserPhone);
    yield put(showLoader());
    const response = yield call(
      verify,
      action.payload.userVerificationCode,
      userPhone
    );
    if (!response.data?.token) {
      yield put(API_VERIFICATION_ERROR);
    }
    yield put(updateCredentials({ apiToken: response.data.token }));
    yield put(fetchUserRequest());
    yield put(verificationSuccess());
  } catch (error) {
    if (error.response?.status === 400) {
      if (error.response.data?.status === "expired_otp_code") {
        yield put(verificationFail(API_VERIFICATION_ERROR));
      } else {
        yield put(verificationFail(API_VERIFICATION_BAD_CREDENTIALS_ERROR));
      }
    } else if (error.response?.status === 404) {
      yield put(verificationFail(API_VERIFICATION_MISSING_CODE_ERROR));
    } else if (error.response?.status === 409) {
      yield put(verificationFail(API_USER_IS_CLUB_MEMBER_ERROR));
    } else {
      put(verificationFail(API_VERIFICATION_ERROR));
    }
  } finally {
    yield put(hideLoader());
  }
}

function* authSaga() {
  yield takeLatest(loginRequest.toString(), loginUser);
  yield takeLatest(registerRequest.toString(), registerUser);
  yield takeLatest(verificationRequest.toString(), verifyUser);
}

export default authSaga;
