import React, { useEffect } from "react";
import failureIcon from "../../assets/images/failureIcon.svg";
import { ERROR_NOTIFICATION_PAGE } from "../../constants";
import { Link, useHistory } from "react-router-dom";
import { errorNotificationPageTexts } from "../../texts";
import { setCurrentPage } from "../../store/slices/currentPageSlice";
import { useDispatch } from "react-redux";
import { routes } from "../../routes";

import "./ErrorNotificationPage.scss";

function ErrorNotification(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setCurrentPage(ERROR_NOTIFICATION_PAGE));
  }, []);

  const submitForm = () => {
    history.push(routes.finishProcessPage);
  };

  return (
    <div className="errorNotification">
      <img className="errorIcon" alt="" src={failureIcon} />
      <div className="errorMessageSummery">
        {errorNotificationPageTexts.errorNotificationPageMainText}
      </div>
      <div className="errorMessageDetails">
        {errorNotificationPageTexts.errorNotificationPageErrorDetailsText}
      </div>
      <button className="submitFormAgain" onClick={submitForm} type="button">
        {errorNotificationPageTexts.errorNotificationPageRetryLinkText}
      </button>
      <Link className="quitFormLink" to={routes.homePage}>
        {errorNotificationPageTexts.errorNotificationPageEndProcessLinkText}
      </Link>
    </div>
  );
}

export default ErrorNotification;
