import React, { useEffect } from "react";
import notificationIcon from "../../../assets/images/notification-important.svg";
import { useHistory } from "react-router-dom";
import { EXISTING_MEMBER_NOTIFICATION_PAGE } from "../../../constants";
import { successNotificationPageTexts } from "../../../texts";
import { setCurrentPage } from "../../../store/slices/currentPageSlice";
import { useDispatch } from "react-redux";
import { routes } from "../../../routes";
import { existingClubMemberPageTexts } from "../../../texts";
import "./ExistingClubMemberPage.scss";

function ExistingClubMemberPage() {

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setCurrentPage(EXISTING_MEMBER_NOTIFICATION_PAGE));
  }, []);

  const submitForm = () => {
    history.push(routes.homePage)
  };

  return (
    <div className="clubMemberNotification">
      <img className="notificationIcon" alt="" src={notificationIcon} />
      <div className="existingClubMemberMessageSummery">
        {existingClubMemberPageTexts.messageHeader}
      </div>
      <div className="existingClubMemberMessageDetails">
        {existingClubMemberPageTexts.messageDetails}
      </div>
      <button
        className="finishProcessAndSubmit"
        onClick={submitForm}
        type="button"
      >
        {successNotificationPageTexts.successNotificationPageEndProcessText}
      </button>
    </div>
  )
}

export default ExistingClubMemberPage;
