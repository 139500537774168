import React, { useEffect } from "react";
import successIcon from "../../assets/images/successIcon.svg";
import { Link, useHistory } from "react-router-dom";
import { SUCCESS_NOTIFICATION_PAGE } from "../../constants";
import { successNotificationPageTexts } from "../../texts";
import { setCurrentPage } from "../../store/slices/currentPageSlice";
import { useDispatch } from "react-redux";
import { routes } from "../../routes";

import "./SuccessNotificationPage.scss";


function SuccessNotificationPage(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setCurrentPage(SUCCESS_NOTIFICATION_PAGE));
  }, []);

  const submitForm = () => {
    history.push(routes.homePage)
  };

  return (
    <div className="successNotification">
      <img className="successIcon" alt="" src={successIcon} />
      <div className="successMessageSummery">
        {successNotificationPageTexts.successNotificationPageSuccessText}
      </div>
      <div className="successMessageDetails">
        {successNotificationPageTexts.successNotificationPageSuccessDetailsText}
      </div>
      <button
        className="finishProcessAndSubmit"
        onClick={submitForm}
        type="button"
      >
        {successNotificationPageTexts.successNotificationPageEndProcessText}
      </button>
      <Link className="goToHomepageLink" to={routes.homePage}>
        {
          successNotificationPageTexts.successNotificationPageGoToHomepageLinkText
        }
      </Link>
    </div>
  );
}

export default SuccessNotificationPage;
