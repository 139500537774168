import * as Yup from "yup";

const phoneRegExp = /^0(5[^7])[0-9]{7}$/;
const idRegex = /^[0-9]{9}$/g;

export const LoginPageSchema = Yup.object().shape({
  userId: Yup.string()
    .required("שדה זה חובה למילוי")
    .matches(idRegex, "נא להקליד מספר תעודת זהות תקין"),
  userPhone: Yup.string()
    .required("שדה זה חובה למילוי")
    .matches(phoneRegExp, "נא להקליד מספר טלפון תקין"),
});
