import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { RegisterForm } from "./RegisterForm/RegisterForm";
import { RegisterPageSchema } from "./registerPageSchema";
import { REGISTER_PAGE } from "../../../constants";
import { Modal } from "../../Modal/Modal";
import LoginOrRegisterFailure from "../../Notifications/LoginOrRegisterFailure.js/LoginOrRegisterFailure";
import {
  registerRequest,
  resetError,
} from "../../../store/slices/registerSlice";
import { setCurrentPage } from "../../../store/slices/currentPageSlice";
import { routes } from "../../../routes";

import "./RegisterPage.scss";

function RegisterPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isRegisterFinished = useSelector(
    (state) => state.registerReducer.isRegisterFinished
  );
  const error = useSelector((state) => state.registerReducer.error);

  useEffect(() => {
    dispatch(setCurrentPage(REGISTER_PAGE));
    dispatch(resetError());
  }, []);

  useEffect(() => {
    if (isRegisterFinished && !error) {
      history.push(routes.registerVerificationPage);
    }
  }, [isRegisterFinished, error, history]);

  const submit = useCallback((values) => {
    dispatch(registerRequest(values));
  }, []);

  const formikInitialValues = { userEmail: "", userPhone: "" };

  return (
    <div className="registerPage">
      <Modal
        notificationClass={"notificationClass"}
        show={isRegisterFinished && error}
        handleClose={() => dispatch(resetError())}
      >
        <LoginOrRegisterFailure onFinish={() => dispatch(resetError())} />
      </Modal>
      <div className="registerGreetingText">הפרטים שלי</div>
      <Formik
        onSubmit={submit}
        children={(props) => <RegisterForm {...props} />}
        initialValues={formikInitialValues}
        validationSchema={RegisterPageSchema}
      />
    </div>
  );
}
export default RegisterPage;
