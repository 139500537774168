import { combineReducers } from "redux";
import userReducer from "../store/slices/userSlice";
import userCredentialsReducer from "../store/slices/userCredentialsSlice";
import loaderReducer from "../store/slices/loaderSlice";
import loginReducer from "../store/slices/loginSlice";
import registerReducer from "../store/slices/registerSlice";
import verificationReducer from "../store/slices/verificationSlice";
import userDetailsReducer from "../store/slices/userDetailsSlice";
import userAdditionalDetailsReducer from "../store/slices/userAdditionalDetailsSlice";
import userFinishProcessReducer from "../store/slices/userFinishProcessSlice";
import currentPageReducer from "../store/slices/currentPageSlice";
import userUploadDocumentsReducer from "../store/slices/userUploadDocumentsSlice";
import faqReducer from "../store/slices/faqSlice";

const rootReducer = combineReducers({
  userReducer,
  userCredentialsReducer,
  loaderReducer,
  loginReducer,
  registerReducer,
  verificationReducer,
  userDetailsReducer,
  userAdditionalDetailsReducer,
  userFinishProcessReducer,
  currentPageReducer,
  userUploadDocumentsReducer,
  faqReducer,
});

export default rootReducer;
