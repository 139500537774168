export const WELCOME_PAGE = "welcomePage";
export const LOGIN_PAGE = "loginPage";
export const REGISTER_PAGE = "registerPage";
export const VERIFICATION_PAGE = "verificationPage";
export const USER_DETAILS_PAGE = "userDetailsPage";
export const UPLOAD_DOCUMENT_PAGE = "uploadDocumentPage";
export const ADDITIONAL_DETAILS_PAGE = "additionalDetailsPage";
export const FINISH_PROCESS_PAGE = "finishProcessPage";
export const ERROR_NOTIFICATION_PAGE = "errorNotificationPage";
export const SUCCESS_NOTIFICATION_PAGE = "successNotificationPage";
export const EXISTING_MEMBER_NOTIFICATION_PAGE = "existingClubMemberPage";

export const API_LOGIN_ERROR = "apiLoginError";
export const API_REGISTER_ERROR = "apiRegisterError";
export const API_VERIFICATION_ERROR = "apiVerificationError";
export const API_VERIFICATION_BAD_CREDENTIALS_ERROR = "apiVerificationBadCredentialsError";
export const API_VERIFICATION_MISSING_CODE_ERROR = "apiVerificationMissingCodeError";
export const API_USER_IS_CLUB_MEMBER_ERROR = "apiVerificationIsClubMemberError";

export const API_USER_DETAILS_ERROR = "apiUserDetailsError";
export const API_SERVER_USER_UPDATE_ERROR = "apiUserDetailsError";
