import {
  WELCOME_PAGE,
  USER_DETAILS_PAGE,
  UPLOAD_DOCUMENT_PAGE,
  ADDITIONAL_DETAILS_PAGE,
  LOGIN_PAGE,
  FINISH_PROCESS_PAGE,
  ERROR_NOTIFICATION_PAGE,
  SUCCESS_NOTIFICATION_PAGE,
  EXISTING_MEMBER_NOTIFICATION_PAGE,
} from "../../constants";
import { REGISTER_PAGE } from "../../constants";
import { VERIFICATION_PAGE } from "../../constants";
import welcomePageImage from "../../assets/images/homePage.jpg";
import registerPageImage from "../../assets/images/registerLoginAndVerifyPage.jpg";
import userDetailsImage from "../../assets/images/userDetailsPage.jpg";
import uploadDocumentsImage from "../../assets/images/uploadDocumentsPage.jpg";
import additionalDetailsImage from "../../assets/images/additionalDetailsPage.jpg";
import finishProcessImage from "../../assets/images/finishProcessPage.jpg";

export const pageToImageMapping = {
  [WELCOME_PAGE]: {
    url: welcomePageImage,
    nextPages: [REGISTER_PAGE, LOGIN_PAGE],
  },
  [REGISTER_PAGE]: { url: registerPageImage, nextPages: [VERIFICATION_PAGE] },
  [LOGIN_PAGE]: { url: registerPageImage, nextPages: [VERIFICATION_PAGE] },
  [VERIFICATION_PAGE]: { url: registerPageImage, nextPages: [USER_DETAILS_PAGE] },
  [USER_DETAILS_PAGE]: { url: userDetailsImage, nextPages: [UPLOAD_DOCUMENT_PAGE] },
  [UPLOAD_DOCUMENT_PAGE]: { url: uploadDocumentsImage, nextPages: [ADDITIONAL_DETAILS_PAGE] },
  [ADDITIONAL_DETAILS_PAGE]: { url: additionalDetailsImage, nextPages: [FINISH_PROCESS_PAGE] },
  [FINISH_PROCESS_PAGE]: { url: finishProcessImage, nextPages: [ERROR_NOTIFICATION_PAGE, SUCCESS_NOTIFICATION_PAGE] },
  [ERROR_NOTIFICATION_PAGE]: { url: finishProcessImage },
  [SUCCESS_NOTIFICATION_PAGE]: { url: finishProcessImage },
  [EXISTING_MEMBER_NOTIFICATION_PAGE]: { url: finishProcessImage },
};
