import { createSlice } from "@reduxjs/toolkit";

const userUploadDocumentsSlice = createSlice({
  name: "uploadDocuments",
  initialState: {},
  reducers: {
    uploadDocumentsRequest(state, action) {
      for (let file of action.payload.files) {
        state[file.name] = {
          percentage: 0,
          isUploadSuccessesfull: false,
          isUploadFinished: false,
          id: -1,
          category: action.payload.category,
        };
      }
    },
    fetchUploadedDocumentsRequest(state, action) {},
    deleteDocumentsRequest(state, action) {},
    deleteDocument(state, action) {
      delete state[action.payload];
    },
    updateProgress(state, action) {
      const { fileName, percentage } = action.payload;
      state[fileName].percentage = percentage;
    },
    uploadSuccess(state, action) {
      const { fileName, id, category } = action.payload;
      if (state[fileName]) {
        state[fileName].isUploadSuccessesfull = true;
        state[fileName].id = id;
        state[fileName].category = category;
        state[fileName].percentage = 100;
        state[fileName].isUploadFinished = true;
      } else {
        state[fileName] = {
          isUploadSuccessesfull: true,
          id,
          category,
          percentage: 100,
          isUploadFinished: true,
        };
      }
    },
    uploadFail(state, action) {
      const { fileName } = action.payload;
      state[fileName].isUploadSuccessesfull = false;
      state[fileName].isUploadFinished = true;
    },
  },
});

export const {
  uploadDocumentsRequest,
  deleteDocumentsRequest,
  deleteDocument,
  updateProgress,
  uploadSuccess,
  uploadFail,
  fetchUploadedDocumentsRequest,
} = userUploadDocumentsSlice.actions;
export default userUploadDocumentsSlice.reducer;
