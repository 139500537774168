import React, { useCallback, useState } from "react";
import TextareaInput from "../../../FormElements/TextareaInput/TextareaInput";
import ButtonGroupInput from "../../../FormElements/ButtonGroupInput/ButtonGroupInput";
import DigitalSignatureInput from "../../../FormElements/DigitalSignatureInput/DigitalSignatureInput";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { MultiInput } from "../../../FormElements/MultiSelecetInput/MultiInput/MultiInput";
import { Modal } from "../../../Modal/Modal";

import "./FinishProcessForm.scss";

import PolicyAndUseTerms from "../../PolicyAndUseTerms/PolicyAndUseTerms";
import { routes } from "../../../../routes";

function FinishProcessForm(props) {
  const {
    values: {
      pharmacistAdvice,
      preferredVarieties,
      orderAddons,
      isApproveCommercialContent,
      digitalSignature,
      morningDelivery,
      noonDelivery,
      eveningDelivery,
      isPolicyAndUsageAccepted,
    },
    errors,
    touched,
    handleSubmit,
    isValid,
    setFieldTouched,
    setFieldValue,
  } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const change = (name, value) => {
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  return (
    <form
      className="finishProcessForm"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Modal
        className="fullPage"
        show={isOpenModal}
        handleClose={() => setIsOpenModal(false)}
      >
        <PolicyAndUseTerms onFinish={() => setIsOpenModal(false)} />
      </Modal>
      <div className="deliveryAndConsultation">
        <div className="deliveryTime">
          <legend className="delivery">זמן משלוח מועדף</legend>
          <div className="chooseDelivery">
            <div>
              <input
                checked={morningDelivery}
                onChange={(event) =>
                  change("morningDelivery", event.target.checked)
                }
                className="morning"
                type="checkbox"
                name="morningDelivery"
                id="morning"
                value={morningDelivery}
              ></input>
              <label htmlFor="morning">בוקר</label>
            </div>

            <div>
              <input
                checked={noonDelivery}
                onChange={(event) =>
                  change("noonDelivery", event.target.checked)
                }
                className="noon"
                type="checkbox"
                name="noonDelivery"
                id="noon"
                value={noonDelivery}
              ></input>
              <label htmlFor="noon">צהריים</label>
            </div>

            <div>
              <input
                checked={eveningDelivery}
                onChange={(event) =>
                  change("eveningDelivery", event.target.checked)
                }
                className="evening"
                type="checkbox"
                name="eveningDelivery"
                id="evening"
                value={eveningDelivery}
              ></input>
              <label htmlFor="evening">ערב</label>
            </div>
          </div>
        </div>

        <ButtonGroupInput
          required
          className="advice"
          value={pharmacistAdvice}
          values={{
            מעוניין: "yes",
            "לא מעוניין": "no",
          }}
          id={"adpharmacistAdvicevice"}
          touched={touched}
          errors={errors}
          onChange={(value) => change("pharmacistAdvice", value)}
          label={"ייעוץ רוקח"}
        />
      </div>

      <div className="varieties">
        <MultiInput
          label={"זנים מועדפים"}
          value={preferredVarieties}
          id={"preferredVarieties"}
          touched={touched}
          errors={errors}
          onChange={(value) => change("preferredVarieties", value)}
        />
      </div>

      <div className="addOnTextarea">
        <TextareaInput
          className="addOns"
          placehoder="ציין דברים חשובים, כך נדע לתת לך את השירות הטוב ביותר… (עד 40 תווים)"
          value={orderAddons || ""}
          rows={5}
          id={"orderAddons"}
          touched={touched}
          errors={errors}
          onChange={(event) => change("orderAddons", event.target.value)}
          label="חשוב לי בהזמנה ש… (אופציונאלי)"
        />
      </div>

      <div className="agreeToTerms">
          <DigitalSignatureInput
            value={digitalSignature}
            id={"digitalSignature"}
            onChange={(value) => change("digitalSignature", value)}
          />
        

        <div>
          <input
            className="agreeToCommercial"
            checked={isApproveCommercialContent}
            type="checkbox"
            name="isApproveCommercialContent"
            id="isApproveCommercialContent"
            onChange={(event) =>
              change("isApproveCommercialContent", event.target.checked)
            }
          />
          <label htmlFor="isApproveCommercialContent">
            אני מאשר/ת קבלת תוכן פרסומי למייל או לנייד
          </label>
        </div>
      </div>

      <div className="isPolicyAndUsageAccepted">
        <input
          checked={isPolicyAndUsageAccepted}
          type="checkbox"
          name="isPolicyAndUsageAccepted"
          id="isPolicyAndUsageAccepted"
          onChange={(event) =>
            change("isPolicyAndUsageAccepted", event.target.checked)
          }
        />
        <label htmlFor="isPolicyAndUsageAccepted">
          <span>קראתי ואני מאשר/ת את</span>
          <button
            type="button"
            onClick={() => setIsOpenModal(true)}
            className="linkToPolicy"
          >
            תנאי השימוש ומדיניות הפרטיות
          </button>
        </label>
      </div>

      <div className="registerOrBack">
        <Link className="back" to={routes.additionalDetailsPage}>
          חזרה
        </Link>
        <Button
          className="registerButton"
          type="submit"
          variant="contained"
          disabled={!isValid}
        >
          הירשם
        </Button>
      </div>
    </form>
  );
}
export default FinishProcessForm;
