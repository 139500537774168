import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VERIFICATION_PAGE } from "../../../constants";
import { Formik } from "formik";
import VerificationForm from "./VerificationForm/VerificationForm";
import { VerificationSchema } from "./verificationSchema";
import { Link, useHistory, useLocation, Redirect } from "react-router-dom";
import { verificationPageTexts } from "../../../texts";
import { Modal } from "../../Modal/Modal";
import IncorrectDetails from "../../Notifications/IncorrectDetails/IncorrectDetails";
import LoginOrRegisterFailure from "../../Notifications/LoginOrRegisterFailure.js/LoginOrRegisterFailure";
import { routes } from "../../../routes";
import {
  verificationRequest,
  resetError,
} from "../../../store/slices/verificationSlice";
import { setCurrentPage } from "../../../store/slices/currentPageSlice";
import {
  API_VERIFICATION_ERROR,
  API_VERIFICATION_MISSING_CODE_ERROR,
  API_USER_IS_CLUB_MEMBER_ERROR
} from "../../../constants";

import "./VerificationPage.scss";

function VerificationPage() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userPhone = useSelector(
    (state) => state.userCredentialsReducer.userPhone
  );
  const isVerificationFinished = useSelector(
    (state) => state.verificationReducer.isVerificationFinished
  );
  const error = useSelector((state) => state.verificationReducer.error);

  const returnToPrevPage = useCallback(() => {
    switch (location.pathname) {
      case routes.loginVerificationPage:
        history.push(routes.loginPage);
        break;
      case routes.registerVerificationPage:
        history.push(routes.registerPage);
        break;
      default:
        history.push(routes.homePage);
    }
  }, [history, location.pathname]);

  const verificationErrorDismiss = useCallback(() => {
    dispatch(resetError());
    returnToPrevPage();
  }, [dispatch, returnToPrevPage]);

    const showModalPerError = useCallback((errorCode) => {
    if (errorCode === API_VERIFICATION_ERROR || errorCode === API_VERIFICATION_MISSING_CODE_ERROR) {
      return <LoginOrRegisterFailure onFinish={verificationErrorDismiss} />
    }
    else if (errorCode === API_USER_IS_CLUB_MEMBER_ERROR) {
      return <Redirect
        to={{
          pathname: routes.existingClubMemberNotification,
        }}
      />
    }
    else {
      return <IncorrectDetails onFinish={verificationErrorDismiss} />
    }
  }, [verificationErrorDismiss])

  useEffect(() => {
    if (!userPhone) {
      returnToPrevPage();
      return;
    }
    dispatch(setCurrentPage(VERIFICATION_PAGE));
    dispatch(resetError());

    return () => {
      dispatch(resetError());
    };
  }, []);

  useEffect(() => {
    if (isVerificationFinished && !error) {
      history.push(routes.userDetailsPage);
    }
  }, [isVerificationFinished, error, history]);

  const submit = useCallback((values, userPhone) => {
    dispatch(verificationRequest({ ...values, userPhone }));
  }, []);

  const formikInitialValues = { userVerificationCode: "" };
  return (
    <div className="verification">
      <Modal
        notificationClass={"notificationClass"}
        show={isVerificationFinished && error}
        handleClose={verificationErrorDismiss}
      >
        {showModalPerError(error)}
      </Modal>
      <div className="verificationText">
        {verificationPageTexts.verificationPageMainText}
      </div>
      <div className="verificationCodeExplenationText">
        {verificationPageTexts.verificationPageExplainationText + userPhone?.slice(-4)}
      </div>
      <Formik
        onSubmit={submit}
        children={(props) => <VerificationForm {...props} />}
        initialValues={formikInitialValues}
        validationSchema={VerificationSchema}
      />
      <Link className="resendCodeText" to="#">
        {verificationPageTexts.verificationPageResendCodeText}
      </Link>
      <Link className="previousPage" to={routes.homePage}>
        {verificationPageTexts.verificationPageGoBackLinkText}
      </Link>
    </div>
  );
}
export default VerificationPage;
