import { createSlice } from "@reduxjs/toolkit";

/**
 * "createSlice" wraps reducer function with produce from the Immer library.
 * This means you can write code that "mutates" the state inside the reducer,
 * and Immer will safely return a correct immutably updated result.
 */
const verificationSlice = createSlice({
  name: "verification",
  initialState: {
    isVerificationFinished: false,
    error: null,
  },
  reducers: {
    verificationRequest(state, action) {},
    verificationSuccess(state, action) {
      state.isVerificationFinished = true;
    },
    verificationFail(state, action) {
      state.error = action.payload;
      state.isVerificationFinished = true;
    },
    resetError(state, action) {
      state.error = null;
      state.isVerificationFinished = false;
    },
  },
});

export const {
  verificationRequest,
  verificationSuccess,
  verificationFail,
  resetError,
} = verificationSlice.actions;

export default verificationSlice.reducer;
